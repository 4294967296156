import React from "react";
import styles from "./checkbox.module.css";

const CheckboxWithLabel = ({ checked, onCheckboxChange, label }) => {
  const handleCheckboxChange = () => {
    onCheckboxChange(!checked);
  };

  return (
    <div className={styles.cntr}>
      <input
        checked={checked}
        type="checkbox"
        className={`${styles.hidden_xs_up} ${styles.cbx}`}
        onChange={handleCheckboxChange}
      />
      <label className={styles.cbx} onClick={handleCheckboxChange}></label>
      <span className={styles.checkbox_label_text}>{label}</span>
    </div>
  );
};

export default CheckboxWithLabel;
