import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styles from "./more.module.css";

function MorePopup({ items, isOpen, closeMenu }) {
  const popupRef = useRef(null); // Create a ref for the popup

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeMenu]);

  return (
    <div
      id="morePopup"
      ref={popupRef}
      className={`${styles.dropdown_menu} ${isOpen ? styles.open : ""}`}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={styles.platform_item}
          onClick={(e) => {
            e.stopPropagation();
            item.action();
            closeMenu();
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}

export default MorePopup;
