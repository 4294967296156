import React from "react";

const BackIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9121686"
  >
    <path
      d="m67.25 8.8528c1.1716-1.17157 3.0711-1.17157 4.2427 0 1.1715 1.1716 1.1715 3.0711 0 4.2426l-36.904 36.904 36.9052 36.9052c1.1716 1.1715 1.1716 3.071 0 4.2426s-3.0711 1.1716-4.2426 0l-38.7444-38.7444c-.6558-.6558-.9445-1.5396-.8662-2.3962-.0825-.8607.2058-1.7502.8649-2.4094z"
      fill={color}
    ></path>
  </svg>
);

export default BackIcon;
