import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import CancelIcon from "../../../assets/SVG/CancelIcon";
import CheckmarkIcon from "../../../assets/SVG/CheckmarkIcon";

const CreatePassword = ({ onNext, onBack, handleInputChange, formData }) => {
  const { createAccount } = useContext(AuthContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const [passwordRequirements, setPasswordRequirements] = useState({
    number: false,
    letter: false,
    special: false,
    min6: false,
    min8: false,
  });

  function hasMinimumLengthSix(password) {
    return password?.length >= 6;
  }

  function hasMinimumLengthEight(password) {
    return password?.length >= 8;
  }

  function hasNumber(password) {
    return /\d/.test(password);
  }

  function hasLetter(password) {
    if (typeof password !== "string") {
      return false;
    }
    return /[a-zA-Z]/.test(password);
  }

  function hasSpecialCharacter(password) {
    return /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()_]/.test(password);
  }

  function hasUppercase(password) {
    return /[A-Z]/.test(password);
  }

  useEffect(() => {
    const password = formData.password;
    setPasswordRequirements({
      number: hasNumber(password),
      letter: hasLetter(password),
      special: hasSpecialCharacter(password),
      uppercase: hasUppercase(password),
      min6: hasMinimumLengthSix(password),
      min8: hasMinimumLengthEight(password),
    });
  }, [formData.password]);

  const canContinue =
    passwordRequirements.number &&
    passwordRequirements.letter &&
    passwordRequirements.min6 &&
    passwordRequirements.uppercase;

  async function handleCreateAccount() {
    setLoading(true);

    setTimeout(() => {
      onNext();
      setLoading(false);
    }, 1200);
  }

  const requirementsCount =
    Object.values(passwordRequirements).filter(Boolean).length;

  const strengthIndicator = () => {
    switch (requirementsCount) {
      case 1:
        return { color: "red", text: "Weak", activeBars: 1 };
      case 2:
        return { color: "rgba(253, 218, 13, 1)", text: "Good", activeBars: 2 };
      case 3:
        return { color: "lightgreen", text: "Strong", activeBars: 3 };
      case 4:
        return { color: "lightgreen", text: "Strong", activeBars: 3 };
      case 5:
        return { color: "lightgreen", text: "Strong", activeBars: 3 };
      case 6:
        return { color: "green", text: "Very Strong", activeBars: 4 };
      default:
        return { color: "green", text: "Very Strong", activeBars: 4 };
    }
  };

  const indicatorStyles = strengthIndicator();

  return (
    <div className={styles.sidebar}>
      <img
        alt="logo"
        src="/BrandLinkLogoBlue.png"
        className={styles.logo_icon}
      ></img>
      <p className={styles.page_title}>Create Your Password</p>
      <p className={styles.page_subtitle}>Please create your new password</p>

      <p className={styles.login_input_titles}>Password</p>
      <div className={styles.email_input}>
        <img
          className={styles.auth_input_icon}
          alt="password"
          src="/PasswordIcon.png"
        ></img>
        <input
          type="password"
          value={formData.password}
          onChange={(event) =>
            handleInputChange("password", event.target.value)
          }
          className={styles.input}
        ></input>
      </div>
      {requirementsCount !== 0 && (
        <div className={styles.password_strength}>
          <p className={styles.password_strength_title}>
            {indicatorStyles.text}
          </p>
          <div className={styles.password_strength_indicator}>
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className={styles.password_strength_line}
                style={{
                  backgroundColor:
                    index < indicatorStyles.activeBars
                      ? indicatorStyles.color
                      : "lightgrey",
                }}
              ></div>
            ))}
          </div>
          <p className={styles.requirements}>Your password must contain</p>
          <div className={styles.password_strength_validations}>
            <div className={styles.password_requirement}>
              <div
                className={styles.cancel_bg}
                style={{
                  backgroundColor: passwordRequirements.min6
                    ? "rgba(0, 255, 0, 0.1)"
                    : "rgba(0, 0, 0, 0.075)",
                }}
              >
                <CancelIcon color="rgba(0, 0, 0, 0.5)" width={14} height={14} />
              </div>
              <p className={styles.password_requirement_text}>
                At least 6 Characters
              </p>
            </div>
            <div className={styles.password_requirement}>
              <div
                className={styles.cancel_bg}
                style={{
                  backgroundColor: passwordRequirements.number
                    ? "rgba(0, 255, 0, 0.1)"
                    : "rgba(0, 0, 0, 0.075)",
                }}
              >
                <CancelIcon color="rgba(0, 0, 0, 0.5)" width={14} height={14} />
              </div>
              <p className={styles.password_requirement_text}>
                At least 1 Number
              </p>
            </div>
            <div className={styles.password_requirement}>
              <div
                className={styles.cancel_bg}
                style={{
                  backgroundColor: passwordRequirements.letter
                    ? "rgba(0, 255, 0, 0.1)"
                    : "rgba(0, 0, 0, 0.075)",
                }}
              >
                <CancelIcon color="rgba(0, 0, 0, 0.5)" width={14} height={14} />
              </div>
              <p className={styles.password_requirement_text}>
                At least 1 Letter
              </p>
            </div>
            <div className={styles.password_requirement}>
              <div
                className={styles.cancel_bg}
                style={{
                  backgroundColor: passwordRequirements.uppercase
                    ? "rgba(0, 255, 0, 0.1)"
                    : "rgba(0, 0, 0, 0.075)",
                }}
              >
                <CancelIcon color="rgba(0, 0, 0, 0.5)" width={14} height={14} />
              </div>
              <p className={styles.password_requirement_text}>
                At least 1 Uppercase
              </p>
            </div>
          </div>
        </div>
      )}

      {/* <p className={styles.login_input_titles}>Password</p>
      <div className={styles.email_input}>
        <img
          className={styles.auth_input_icon}
          alt="password"
          src="/PasswordIcon.png"
        ></img>
        <input
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          className={styles.input}
        ></input>
      </div> */}
      <div className={styles.spacer}></div>

      {error && <p className={styles.error_text}>{error}</p>}
      {canContinue ? (
        <div className={styles.page_button} onClick={handleCreateAccount}>
          {loading ? (
            <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
          ) : (
            <p className={styles.join_button_text}>Continue</p>
          )}
        </div>
      ) : (
        <div className={styles.page_button_inactive}>
          <p className={styles.join_button_text}>Continue</p>
        </div>
      )}
      <p className={styles.previous_step} onClick={onBack}>
        Previous Step
      </p>
    </div>
  );
};

export default CreatePassword;
