import React from "react";
import RequestAccessComponent from "../../components/AuthComponents/RequestAccess/RequestAccess";

const RequestAccessPage = () => {
  return (
    <div>
      <RequestAccessComponent />
    </div>
  );
};

export default RequestAccessPage;
