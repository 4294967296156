import React from "react";

const PlayIcon = ({
  width = 10,
  height = 10,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_5577228"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path
      d="m468.8 235.007-401.359-231.73a24.2 24.2 0 0 0 -12.087-3.285h-.07a24.247 24.247 0 0 0 -12.094 3.287 24 24 0 0 0 -12.11 20.992v463.456a24.186 24.186 0 0 0 36.36 20.994l401.36-231.731a24.238 24.238 0 0 0 0-41.983z"
      fill-rule="evenodd"
    ></path>
  </svg>
);

export default PlayIcon;
