import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ManageCampaignComponents from "../../components/PortalComponents/ManageCampaignComponents/ManageCampaignComponents";

const ManageCampaignsPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<ManageCampaignComponents />}
        activeText="Campaigns"
      />
    </>
  );
};

export default ManageCampaignsPage;
