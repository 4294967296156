import React from "react";

const SearchIcon = ({
  width = 18,
  height = 18,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3287968"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <path
      fill={color}
      clip-rule="evenodd"
      d="m217.257.879c-119.988 0-217.257 97.269-217.257 217.257s97.269 217.257 217.257 217.257 217.257-97.269 217.257-217.257-97.269-217.257-217.257-217.257zm285.789 500.901c-12.089 12.302-31.975 12.476-44.277.388l-86.843-85.334c16.521-12.879 31.39-27.774 44.247-44.314l86.485 84.983c12.302 12.088 12.477 31.974.388 44.277zm-285.789-440.411c86.58 0 156.766 70.187 156.766 156.766s-70.187 156.766-156.766 156.766c-86.58 0-156.766-70.187-156.766-156.766 0-86.579 70.187-156.766 156.766-156.766z"
      fill-rule="evenodd"
    ></path>
  </svg>
);

export default SearchIcon;
