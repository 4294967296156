import React, { useState } from "react";
import styles from "./stages.module.css";

export default function CampaignInformation({
  newCampaign,
  handleInputChange,
}) {
  return (
    <div className={styles.campaign_stage}>
      <p className={styles.account_targeting}>Campaign Information</p>
      <div className={styles.question_layout}>
        <p className={styles.question_title}>Name</p>
        <input
          className={styles.question_single_line}
          value={newCampaign.campaign_name}
          onChange={(e) => handleInputChange("campaign_name", e.target.value)}
        ></input>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.question_layout}>
        <p className={styles.question_title}>Description</p>
        <textarea
          className={styles.question_box}
          value={newCampaign.campaign_description}
          onChange={(e) =>
            handleInputChange("campaign_description", e.target.value)
          }
        ></textarea>
      </div>
    </div>
  );
}
