export const default_data = {
  data: [
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "instagram",
      external_id: null,
      url: "https://www.instagram.com/instagram/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDaDVMq5CUHy%2BkcsdYgF20nDsuUwkRjFVav5M3TrTU9h7%2BxcZBxCJ2RhABotd8JjaVVGHFCcdShtZRKywU%2BQReCwgNlJUwVYqIiahjW32e%2BqWA%3D%3D",
      full_name: "Instagram",
      introduction: "Discover what's next on Instagram 🔎✨",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: null,
      age_group: null,
      language: "en",
      follower_count: 651606250,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.0005441507045090497,
      average_likes: 354572,
      average_views: null,
      creator_location: null,
      contact_details: [
        {
          type: "tiktok",
          value: "https://www.tiktok.com/@6904431186812453894",
        },
      ],
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "cristiano",
      external_id: null,
      url: "https://www.instagram.com/cristiano/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDb05bk9EAgW7oQoJCzCEnmGmUuoxbOW5tBwsZsGrK%2FS7yWdNkP7y%2B1pFCfs%2BJuNwg4LMGQUZTpkh%2BW3cdViohxwWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Cristiano Ronaldo",
      introduction:
        "Join my NFT journey on @Binance. Click the link below to get started.",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "MALE",
      age_group: null,
      language: "en",
      follower_count: 600709593,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.01255094489559783,
      average_likes: 7539473,
      average_views: null,
      creator_location: null,
      contact_details: null,
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "leomessi",
      external_id: null,
      url: "https://www.instagram.com/leomessi/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDathq8InmpwK5fXf5yRd0lvwwzWtYvkGxiYWea7m4FhfrQlPrPMJwtCKHIBWowzHHlz38kWrkU6TTEkGmKBpNdgWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Leo Messi",
      introduction:
        "Bienvenidos a la cuenta oficial de Instagram de Leo Messi / Welcome to the official Leo Messi Instagram account",
      is_verified: true,
      platform_account_type: "BUSINESS",
      gender: "MALE",
      age_group: null,
      language: "es",
      follower_count: 483079442,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.01526787803153917,
      average_likes: 7375598,
      average_views: null,
      creator_location: null,
      contact_details: null,
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "selenagomez",
      external_id: null,
      url: "https://www.instagram.com/selenagomez/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDbju279wv%2FGu1tNVXNCvT1jv7GDHOwrRjnlqluHBMkq8u0cCt33CVWbvE%2F06a3tGMkcI%2FOwZVFwqZLbQQZE1bpJWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Selena Gomez",
      introduction:
        "By grace, through faith. \nFounder @rarebeauty \nFounder/CIO @officialwondermind",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 427404360,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.0158924630530208,
      average_likes: 6792508,
      average_views: null,
      creator_location: null,
      contact_details: [
        {
          type: "facebook",
          value: "https://www.facebook.com/Selena",
        },
        {
          type: "linktree",
          value: "https://linktr.ee/selenagomez",
        },
        {
          type: "twitter",
          value: "https://twitter.com/selenagomez",
        },
        {
          type: "tiktok",
          value: "https://www.tiktok.com/@97614653324771328",
        },
      ],
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "kyliejenner",
      external_id: null,
      url: "https://www.instagram.com/kyliejenner/",
      image_url:
        "https://imgp.sptds.icu/v2?c%2BZ6gMi8pzyyj3IdIuQSsDBpwchEsdg%2FtvYkoZ9FuoRJ6t6sqAnqVAVvMK9TUFrRLxYyIgsboE28zfkHSkzXSQyGorvv2%2FCFopu0MN2J48zjMVPeRZ2wFs1ZXiK7U8dT",
      full_name: "Kylie",
      introduction: "@kyliecosmetics @kylieskin @kylieswim @kyliebaby",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 398815599,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.011281471966697069,
      average_likes: 4499227,
      average_views: null,
      creator_location: null,
      contact_details: null,
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "therock",
      external_id: null,
      url: "https://www.instagram.com/therock/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDbrEEwrWumIvR20xZeZXa0LDeIrcniqZeG9S%2F1a5s2Rx3FZdXrWpY%2BmSBJp1l%2FmBGon3rcHA4EfuatZqMhVBzAT",
      full_name: "Dwayne Johnson",
      introduction: "founder",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "MALE",
      age_group: null,
      language: "en",
      follower_count: 389068821,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.0026537798565976585,
      average_likes: 1032503,
      average_views: null,
      creator_location: {
        city: "Los Angeles",
        state: "California",
        country: "United States",
      },
      contact_details: [
        {
          type: "linktree",
          value: "https://linktr.ee/thepivot",
        },
        {
          type: "facebook",
          value: "https://www.facebook.com/DwayneJohnson",
        },
        {
          type: "linktree",
          value: "https://linktr.ee/therock",
        },
        {
          type: "twitter",
          value: "https://twitter.com/therock",
        },
        {
          type: "tiktok",
          value: "https://www.tiktok.com/@6745191554350760966",
        },
        {
          type: "youtube",
          value: "https://www.youtube.com/channel/UCBdw4dLCLLHmTgAOnW4V0hQ",
        },
      ],
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "arianagrande",
      external_id: null,
      url: "https://www.instagram.com/arianagrande/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDZ%2BMq1TZGNxjKugfHND%2FZ8T3kMScHyPZI7Vp30FV%2B%2BUSVU%2B0m8F3glXRLe7ADMWHmuqwte3nH7UDutmwumjHTL3Wwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Ariana Grande",
      introduction: "currently somewhere over the rainbow",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 378347693,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.009500969786539705,
      average_likes: 3594670,
      average_views: null,
      creator_location: null,
      contact_details: [
        {
          type: "tiktok",
          value: "https://www.tiktok.com/@99320625143398400",
        },
      ],
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "kimkardashian",
      external_id: null,
      url: "https://www.instagram.com/kimkardashian/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDat1QLGClxLKQGAKLBUyw%2BxRSPTS3zhap5xFqJy%2FGpuCFqAvcAlg3BJe7FqSsSJAH1qAu%2Bu%2BgK8ZXpRpOIeWaKjWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Kim Kardashian",
      introduction: "@skims @skkn @skkypartners",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 363527006,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.006576185429260791,
      average_likes: 2390621,
      average_views: null,
      creator_location: null,
      contact_details: [
        {
          type: "tiktok",
          value: "https://www.tiktok.com/@7031328604186035205",
        },
      ],
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "beyonce",
      external_id: null,
      url: "https://www.instagram.com/beyonce/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDZamZ5Z7xQIUNO094fHaxAdEdROxQrUtJlaOMG33x3YApIO5e%2Blf3aAIP%2FSmcSnHtKCPakFaER8teFUFqbcDu6JrOPQt%2BBH8xfdE2dL1avFgg%3D%3D",
      full_name: "Beyoncé",
      introduction: "📍ATLANTA, GA",
      is_verified: true,
      platform_account_type: "BUSINESS",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 315869551,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0.004518156294210201,
      average_likes: 1427148,
      average_views: null,
      creator_location: {
        city: "Atlanta",
        state: "Georgia",
        country: "United States",
      },
      contact_details: null,
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
    {
      work_platform: {
        id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
        name: "Instagram",
        logo_url:
          "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
      },
      platform_username: "khloekardashian",
      external_id: null,
      url: "https://www.instagram.com/khloekardashian/",
      image_url:
        "https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDbju279wv%2FGu1tNVXNCvT1jyH47RpEFD3y4cqAjxCjUPcT0QEPbRIlY%2B%2BdtaXAGnTvyomtSVt%2BtdivS47Tu%2BWIxWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D",
      full_name: "Khloé Kardashian",
      introduction:
        "@kardashianshulu \n@goodamerican                                                                                      TikTok 🤍@khloekardashian",
      is_verified: true,
      platform_account_type: "CREATOR",
      gender: "FEMALE",
      age_group: null,
      language: "en",
      follower_count: 311346609,
      subscriber_count: null,
      content_count: null,
      engagement_rate: 0,
      average_likes: 0,
      average_views: null,
      creator_location: null,
      contact_details: null,
      filter_match: {
        brand_sponsors: null,
        creator_age: null,
        creator_brand_affinities: null,
        follower_growth: null,
        subscriber_growth: null,
        creator_gender: null,
        creator_interests: null,
        creator_language: null,
        creator_locations: null,
        creator_lookalikes: null,
        content_count: null,
        instagram_options: {
          reel_views: null,
        },
        audience_age: null,
        audience_gender: null,
        audience_brand_affinities: null,
        audience_interests: null,
        audience_language: null,
        audience_locations: null,
        audience_lookalikes: null,
        topic_relevance: null,
        views_growth: null,
        audience_ethnicity: null,
        audience_credibility_score: null,
        share_count: null,
        save_count: null,
      },
      livestream_metrics: null,
    },
  ],
  metadata: {
    offset: 0,
    limit: 10,
    total_results: 10000,
  },
};
