import React, { useState, useContext } from "react";
import styles from "./inbox.module.css";
import { EmailContext } from "../../../context/EmailContext";
import AiButton from "../UniversalComponents/AiButton/AiButton";

export default function InboxMessageInput(props) {
  const { replyEmail } = useContext(EmailContext);
  const [message, setMessage] = useState("");
  const thread = props.selectedChat;

  function handleInputChange(event) {
    setMessage(event.target.value);
  }
  console.log(thread.id);

  // function findFirstReceivedEmail(emails) {
  //   return emails.find((email) => email.isSenderSelf === false);
  // }

  // console.log(findFirstReceivedEmail(thextractFirstNameread.emails));

  const emailToReplyTo = thread.emails.find(
    (email) => email.isSenderSelf === false
  );

  console.log(emailToReplyTo);
  async function handleSendMessage() {
    if (message.trim() !== "") {
      try {
        const to = [
          {
            display_name: thread?.threadInfo?.contact_email,
            identifier: thread?.threadInfo?.contact_name,
          },
        ];
        const subject = emailToReplyTo.subject;
        const body = message;
        const cc = [];

        await replyEmail(
          thread.threadInfo.account_id,
          emailToReplyTo.email_id,
          to,
          subject,
          body,
          cc
        );
        setMessage("");
      } catch (error) {
        console.error("Error sending email:", error);
      }
    }
  }
  // console.log(findFirstReceivedEmail(thread.threadInfo.emails));

  return (
    <div className={styles.message_input_container}>
      <div className={styles.reply_info}>
        <p className={styles.replying_to}>Replying to:</p>
        <p className={styles.replying_to_email}>
          {thread?.threadInfo?.contact_email}
          {/* ({thread?.from_attendee?.identifier}) */}
        </p>
      </div>
      <textarea
        className={styles.message_input}
        type="text"
        placeholder="Type message..."
        value={message}
        onChange={handleInputChange}
      />
      <div className={styles.message_input_controls}>
        <div className={styles.message_input_controls_left}></div>
        <div className={styles.message_input_controls_right}>
          <div className={styles.ai_button}>
            <AiButton setReply={setMessage} />
          </div>

          <div
            className={styles.send_button_active}
            onClick={handleSendMessage}
          >
            Send
          </div>
        </div>
      </div>
    </div>
  );
}
