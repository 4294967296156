import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import SearchFilterComponent from "./SearchFilterComponent";
import { AuthContext } from "../../../context/AuthContext";
import { PhyllloContext } from "../../../context/PhylloContext";
import CreatorTile from "./CreatorTile";
import LoadingAnimation from "../UniversalComponents/LoadingAnimation";
import UpgradePlan from "../../PopUpComponents/UpgradePlan/UpgradePlan";

import { mapFilters } from "../../../helpers/mapFilters";

import PageControl from "../UniversalComponents/PageContols/PageControl";

import { platform_filter_options } from "./FilterOptions";
import { filter_options } from "./FilterOptions";

import exportCSV from "../../../utils/exportCSV";

export default function CreatorSearchComponent({
  addContactToNewCampaign,
  displayDefaults,
  title,
  type,
}) {
  const { profile, currentFilters, planType } = useContext(AuthContext);
  const {
    phylloCreatorData,
    loading,
    sortByDropdownOptions,
    selectedSortOption,
    setSelectedSortOption,
    handlePhylloSearch,
    searchOffset,
    searchLimit,
    setSearchOffset,
    setSearchLimit,
  } = useContext(PhyllloContext);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  console.log(phylloCreatorData);
  const FILTER_OPTIONS = {
    ...platform_filter_options,
    ...filter_options,
  };

  const creatorListRef = useRef(null);

  const [filterOptions, setFilterOptions] = useState(FILTER_OPTIONS);

  const [currentlyDisplayedSearchResults, setCurrentlyDisplayedSearchResults] =
    useState([]);

  const updateDisplayedResults = () => {
    const start = searchOffset;
    const end = searchOffset + searchLimit;
    setCurrentlyDisplayedSearchResults(phylloCreatorData.slice(start, end));
  };

  const handleNextPage = () => {
    if (searchOffset + searchLimit >= phylloCreatorData.length) {
      setSearchOffset(searchOffset + searchLimit);
      handlePhylloSearch(
        mapFilters(filterOptions),
        true,
        searchOffset + searchLimit
      );
    } else {
      setSearchOffset(searchOffset + searchLimit);
      updateDisplayedResults();
    }

    if (creatorListRef.current) {
      creatorListRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePreviousPage = () => {
    if (searchOffset - searchLimit >= 0) {
      setSearchOffset(searchOffset - searchLimit);
      updateDisplayedResults();
    }

    if (creatorListRef.current) {
      creatorListRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    updateDisplayedResults();
  }, [phylloCreatorData, searchLimit, searchOffset]);

  useEffect(() => {
    if (currentFilters) {
      setFilterOptions(currentFilters);
    }
  }, [currentFilters]);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  function showUpgradePlatformPopup() {
    setIsPopupOpen(true);
  }

  function downloadCSV() {
    console.log("export!");
    exportCSV(phylloCreatorData, "creator-data.csv");
  }

  function updateFilters() {
    // handlePhylloSearch(mapFilters(currentFilters));
  }

  const [hoveredSection, setHoveredSection] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const SortDropdown = () => (
    <div className={styles.sortDropdown}>
      {sortByDropdownOptions.map((option) => (
        <div
          key={option}
          className={
            option === selectedSortOption
              ? styles.sortDropdownItemClicked
              : styles.sortDropdownItem
          }
          onClick={() => handleDropdownSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );

  const handleDropdownSelect = (option) => {
    setSelectedSortOption(option);
    setIsDropdownVisible(false);
    updateFilters();
  };

  const handleCreatorCheck = (index, isChecked) => {
    if (isChecked) {
      setSelectedCreators((prev) => [...prev, index]);
    } else {
      setSelectedCreators((prev) => prev.filter((id) => id !== index));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // If currently selected, unselect all
      setSelectedCreators([]);
    } else {
      // Select all creators by using their indices
      const allIndices = phylloCreatorData.map((_, index) => index);
      setSelectedCreators(allIndices);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (
      selectedCreators.length > 0 &&
      selectedCreators.length === phylloCreatorData.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCreators, phylloCreatorData]);

  function handleCheckboxClick(event) {
    event.stopPropagation();
  }

  return (
    <div className={styles.main_layout}>
      <UpgradePlan isOpen={isPopupOpen} onClose={closePopup} />
      <SearchFilterComponent
        displayDefaults={displayDefaults}
        title={title}
        type={type}
        closePopup={closePopup}
        showUpgradePlatformPopup={showUpgradePlatformPopup}
        isPopupOpen={isPopupOpen}
        FILTER_OPTIONS={FILTER_OPTIONS}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />
      {loading ? (
        <div className={styles.loading_layout}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className={styles.creator_list_controls}>
            <div className={styles.creator_list_left_controls}>
              <p className={styles.creator_found_title}>
                {profile?.last_results_count} Account's Found
              </p>

              {/* {selectedCreators.length !== 0 && (
                <div className={styles.connect_all_button}>Connect All</div>
              )} */}
            </div>
            <div className={styles.creator_list_right_controls}>
              <div>
                <p className={styles.creator_selected_text}>
                  {selectedCreators.length !== 0 &&
                    selectedCreators.length + " creators selected"}
                </p>
              </div>
              {type !== "CampaignCreation" && (
                <div
                  className={`${styles.export_section} ${
                    selectedCreators.length !== 0
                      ? styles.export_section_active
                      : ""
                  }`}
                  onClick={() => {
                    downloadCSV();
                  }}
                >
                  {planType === "Standard" && (
                    <img
                      className={styles.locked_icon}
                      src="/PasswordIcon.png"
                      alt="locked"
                    ></img>
                  )}
                  <p className={styles.export_text}>Export CSV</p>
                </div>
              )}

              <div className={styles.sort_section} onClick={toggleDropdown}>
                <img
                  className={styles.sort_icon}
                  src="/SortIcon.png"
                  alt="sort"
                ></img>
                <p className={styles.sort_text}>
                  Sort by
                  <span className={styles.chosen_sort_text}>
                    {" "}
                    {selectedSortOption}
                  </span>
                </p>
                <img
                  src="/images/general_icons/DownArrowIcon.png"
                  alt="dropdown"
                  className={`${styles.dropdown_arrow_sort} ${
                    isDropdownVisible ? styles.flipped : ""
                  }`}
                />
              </div>
              {isDropdownVisible && <SortDropdown />}
            </div>
          </div>
          {phylloCreatorData !== [] && (
            <div className={styles.creator_list_fields}>
              <div className={styles.checkbox_field}>
                <input
                  type="checkbox"
                  className={styles.ui_checkbox}
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
              <div className={styles.creator_info}>
                <p>Account Info</p>
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Followers")}
                onMouseEnter={() => setHoveredSection("Followers")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Followers" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Followers
                </p>
                {selectedSortOption === "Followers" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Followers" &&
                  hoveredSection === "Followers" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Average Likes")}
                onMouseEnter={() => setHoveredSection("Average Likes")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Average Likes" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Average Likes
                </p>
                {selectedSortOption === "Average Likes" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Average Likes" &&
                  hoveredSection === "Average Likes" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>
              {/* <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Engagement")}
                onMouseEnter={() => setHoveredSection("Engagement")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Engagement" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Engagement Rate
                </p>
                {selectedSortOption === "Engagement" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Engagement" &&
                  hoveredSection === "Engagement" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div> */}
              <div className={styles.connect_button_section}></div>
            </div>
          )}
          <div className={styles.creator_list} ref={creatorListRef}>
            {currentlyDisplayedSearchResults.map((creator, index) => {
              return (
                <CreatorTile
                  key={index}
                  creator={creator}
                  type={type}
                  isChecked={selectedCreators.includes(index)}
                  onCheck={handleCreatorCheck}
                  index={index}
                  addContactToNewCampaign={addContactToNewCampaign}
                />
              );
            })}
          </div>
          <PageControl
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            searchLimit={searchLimit}
            searchOffset={searchOffset}
            totalResults={phylloCreatorData.length}
          />
        </>
      )}
    </div>
  );
}
