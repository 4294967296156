import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreatorProfileComponent from "../../components/PortalComponents/CreatorProfileComponent/CreatorProfile";

const CreatorProfilePage = () => {
  return (
    <>
      <Sidebar
        childComponent={<CreatorProfileComponent />}
        activeText="Social Search"
      />
    </>
  );
};

export default CreatorProfilePage;
