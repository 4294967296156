import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./general_components.module.css";

import "chart.js/auto";

export default function BulletChart(props) {
  const data = props.data;

  return (
    <div
      className={`${styles.bullet_chart} ${
        props.sizeSmall ? styles.bullet_chart_small : ""
      }`}
    >
      {data.map((interest, index) => (
        <div key={index} className={styles.interestItem}>
          <div className={styles.interestItemText}>
            <div className={styles.interestName}>
              {interest.name ?? interest.code}
            </div>
            <div className={styles.interestValue}>
              {interest.value.toFixed(2)}%
            </div>
          </div>
          <div className={styles.horizontalBox}>
            <div
              className={styles.filler}
              style={{ width: `${interest.value}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
