import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();

  const { resetPassword } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  function handleGoBack() {
    navigate("/sign-in");
  }

  async function handleSubmit() {
    try {
      await resetPassword(email);
      navigate("/sign-in");
    } catch (error) {
      const errorCode = error.code;
      console.log("Code:", error.code);
      console.log("Message:", error.message);
      let errorMessage = "An error occurred sending email";
      console.error("Sign-in error:", error);
      setError(errorMessage);
    }
  }

  const canContinue = email !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img
            alt="logo"
            src="/BrandLinkLogo.png"
            className={styles.logo_icon}
          ></img>
          <p className={styles.page_title}>Forgot Password</p>
          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
            <img
              className={styles.auth_input_icon}
              alt="email"
              src="/EmailIcon.png"
            ></img>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
            ></input>
          </div>

          {error && <p className={styles.error_text}>{error}</p>}
          {canContinue ? (
            <div onClick={handleSubmit} className={styles.page_button}>
              <p className={styles.join_button_text}>Reset Password</p>
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Reset Password</p>
            </div>
          )}

          <p className={styles.note}>
            <span className={styles.clickable_text} onClick={handleGoBack}>
              Back to login
            </span>
          </p>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/BrandLinkLandingBackground.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default ForgotPasswordComponent;
