import React from "react";

const CheckmarkIcon = ({
  width = 18,
  height = 18,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.5,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M20 6L9 17l-5-5" stroke={color} />
  </svg>
);

export default CheckmarkIcon;
