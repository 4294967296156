import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import styles from "./settings.module.css";

import { AuthContext } from "../../context/AuthContext";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AccountSettings from "../../components/PortalComponents/SettingsComponents/SettingsPages/AccountSettings";
import EmailSettings from "../../components/PortalComponents/SettingsComponents/SettingsPages/EmailSettings/EmailSettings";
import SocialSettings from "../../components/PortalComponents/SettingsComponents/SettingsPages/SocialsSettings/SocialsSettings";
import SubscriptionSettings from "../../components/PortalComponents/SettingsComponents/SettingsPages/SubscriptionSettings";
import SecuritySettings from "../../components/PortalComponents/SettingsComponents/SettingsPages/SecuritySettings";

import ProflieSettingsIcon from "../../assets/SVG/SettingsIcons/ProflieSettingsIcon";
import SecurityIcon from "../../assets/SVG/SettingsIcons/SecurityIcon";
import MailboxesIcon from "../../assets/SVG/SettingsIcons/MailboxesIcon";
import BillingIcon from "../../assets/SVG/SettingsIcons/BillingIcon";

import LogoutIcon from "../../assets/SVG/LogoutIcon";

const SettingsPage = () => {
  const { logout, profile, sidebarNotifications } = useContext(AuthContext);

  const menuItems = [
    {
      label: "My Profile",
      sublabel: "Full Name, Company, Account Data",
      key: "account",
      component: AccountSettings,
      icon: <ProflieSettingsIcon />,
    },
    {
      label: "Security",
      sublabel: "Manage your login credentials",
      key: "security",
      component: SecuritySettings,
      icon: <SecurityIcon />,
    },
    // {
    //   label: "Mailboxes",
    //   sublabel: "Connect your email accounts",
    //   key: "mailboxes",
    //   component: EmailSettings,
    //   icon: <MailboxesIcon />,
    // },
    // {
    //   label: "Social Accounts",
    //   sublabel: "Connect & monitor your social media",
    //   key: "socials",
    //   component: SocialSettings,
    // },
    {
      label: "Plan & Billing",
      sublabel: "Manage your subscription",
      key: "subscription",
      component: SubscriptionSettings,
      icon: <BillingIcon />,
    },
    // {
    //   label: "Webhooks",
    //   sublabel: "Manage and add a webhook",
    //   key: "subscription",
    //   component: SubscriptionSettings,
    // },
    // {
    //   label: "Notifications",
    //   sublabel: "Manage your Notifications",
    //   key: "notifications",
    //   component: AccountSettings,
    // },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponentKey, setActiveComponentKey] = useState(
    menuItems[0].key
  );

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.key)
    );
    if (activeItem) {
      setActiveComponentKey(activeItem.key);
    }
  }, [location]);

  const renderComponent = () => {
    const activeItem = menuItems.find(
      (item) => item.key === activeComponentKey
    );
    if (!activeItem) return null;

    const Component = activeItem.component;
    return <Component profile={profile} />;
  };

  // const renderIcon = () => {
  //   const activeItem = menuItems.find(
  //     (item) => item.key === activeComponentKey
  //   );
  //   return activeItem ? <activeItem.component /> : null;
  // };

  const handleMenuItemClick = (key) => {
    setActiveComponentKey(key);
    navigate(`/settings/${key}`);
  };

  return (
    <>
      <Sidebar
        admin={true}
        childComponent={
          <div className={styles.page_layout}>
            <p className={styles.settings_title}>Account Settings</p>
            <div className={styles.settings_layout}>
              <div className={styles.sidebar}>
                {menuItems.map((item) => (
                  <div
                    key={item.key}
                    onClick={() => handleMenuItemClick(item.key)}
                    style={{ marginBottom: "15px" }}
                    className={
                      activeComponentKey === item.key
                        ? styles.menu_item_selected
                        : styles.menu_item
                    }
                  >
                    <div className={styles.icon}>
                      {React.cloneElement(item.icon, {
                        color:
                          activeComponentKey === item.key
                            ? "rgba(0, 88, 220, 1)"
                            : "rgba(0, 0, 0, 0.6)",
                      })}
                    </div>
                    <div className={styles.item_label_text}>
                      <p className={styles.item_label}> {item.label}</p>
                      <p className={styles.item_sublabel}> {item.sublabel}</p>
                    </div>
                    {item.label === "Mailboxes" && (
                  <div className={styles.notification_dot_orange}>
                    {sidebarNotifications.disconnected_emails}
                  </div>
                )}
                  </div>
                ))}
                <div className={styles.sign_out} onClick={logout}>
                  Logout
                  <LogoutIcon width={15} height={15} />
                </div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.content_layout}>{renderComponent()}</div>
            </div>
            {/* <div className={styles.content_layout}>{renderComponent()}</div> */}
          </div>
        }
        activeText="Settings"
      />
    </>
  );
};

export default SettingsPage;
