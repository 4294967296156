import React from "react";

const InboxIcon = ({
  width = 15,
  height = 15,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_709669"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 438.272 438.272"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M436.224,194.304L388.608,92.416c-9.216-17.92-27.136-28.672-46.592-28.672H96.768c-19.968,0-37.888,10.752-46.592,29.184
			L2.048,194.816C1.024,196.864,0,198.912,0,201.472v121.856c0,28.16,23.04,51.2,51.2,51.2h335.872c28.16,0,51.2-23.04,51.2-51.2
			V200.96C438.272,198.4,437.76,196.352,436.224,194.304z M306.688,185.088v1.024c-7.168,0-13.312,4.608-14.848,11.776
			c-0.512,2.56-12.8,60.416-72.704,60.416c-59.392,0-72.192-58.368-72.704-60.416c-1.024-7.168-7.68-11.776-14.848-11.776H38.4
			l37.888-80.384c3.584-7.68,11.264-12.8,19.968-12.8h245.248c8.192,0,16.384,4.608,19.968,11.776l37.888,80.384H306.688z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default InboxIcon;
