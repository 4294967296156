function getEmailProviderImage(provider) {
  switch (provider) {
    case "GOOGLE_OAUTH":
      return "/EmailProviders/gmail.png";
    case "OUTLOOK":
      return "/EmailProviders/outlook.png";
    default:
      return "/EmailProviders/mail.png";
  }
}

export default getEmailProviderImage;
