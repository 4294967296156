import React from "react";
import styles from "./legal.module.css";

function PrivacyPolicy() {
  return (
    <iframe
      src={`/privacy-policy.html`}
      className={styles.legal_layout}
      title="Termly Content"
    ></iframe>
  );
}

export default PrivacyPolicy;
