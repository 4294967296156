import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { phyllo_filter_options } from "../components/PortalComponents/CreatorSearchComponents/phyllo_filter_options";
import { mapFilters } from "../helpers/mapFilters";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

export const PhyllloContext = createContext();

export const PhylloProvider = ({ children }) => {
  const {
    profile,
    updateContactsFetchedCount,
    skipUpdate,
    setSkipUpdate,
    updateLastResultsCount,
  } = useContext(AuthContext);

  const [error, setError] = useState(null);

  const [searchLimit, setSearchLimit] = useState(10);
  const [searchOffset, setSearchOffset] = useState(0);

  const [phylloCreatorData, setPhylloCreatorData] = useState(
    profile?.recent_search_information?.contact_search_results || []
  );

  const [loading, setLoading] = useState(false);

  const sortByDropdownOptions = ["Average Likes", "Followers", "Engagement"];
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortByDropdownOptions[0]
  );

  useEffect(() => {
    async function checkAndUpdateContacts() {
      if (skipUpdate) {
        setSkipUpdate(false);
        return;
      }
      if (profile?.recent_search_information?.contact_search_results) {
        const contactIds =
          profile.recent_search_information.contact_search_results
            .map((data) => data.external_id)
            .filter((id) => id && typeof id === "string"); // Validate IDs

        if (contactIds.length > 0) {
          try {
            const contactsQuery = query(
              collection(db, `accounts/${profile.uid}/contacts`),
              where("__name__", "in", contactIds)
            );

            const querySnapshot = await getDocs(contactsQuery);
            const contactsMap = new Map();
            querySnapshot.forEach((doc) => {
              contactsMap.set(doc.id, true);
            });

            const updatedData =
              profile.recent_search_information.contact_search_results.map(
                (data) => ({
                  ...data,
                  isContact: contactsMap.has(data.external_id),
                })
              );

            setPhylloCreatorData((prevData) => {
              if (JSON.stringify(prevData) !== JSON.stringify(updatedData)) {
                return updatedData;
              }
              return prevData;
            });
          } catch (error) {
            console.error("Error updating contacts:", error);
          }
        } else {
          // No valid contact IDs, set all isContact to false
          const updatedData =
            profile.recent_search_information.contact_search_results.map(
              (data) => ({
                ...data,
                isContact: false,
              })
            );
          setPhylloCreatorData(updatedData);
        }
      } else {
        setPhylloCreatorData([]);
      }
    }

    checkAndUpdateContacts();
  }, [profile]);

  async function saveFilterOptions(userId, filterOptions) {
    try {
      const userDocRef = doc(db, "accounts", userId);
      const filterOptionsString = JSON.stringify(filterOptions);

      await setDoc(
        userDocRef,
        {
          recent_search_information: {
            search_filters: filterOptionsString,
          },
        },
        { merge: true }
      );

      console.log("Filter options saved successfully");
    } catch (error) {
      console.error("Error saving filter options: ", error);
    }
  }

  async function saveRecentSearch(contactSearchResults) {
    try {
      const userDocRef = doc(db, "accounts", profile.uid);
      const contactSearchResultsString = JSON.stringify(contactSearchResults);

      await setDoc(
        userDocRef,
        {
          recent_search_information: {
            contact_search_results: contactSearchResultsString,
          },
        },
        { merge: true }
      );

      console.log("Filter options saved successfully");
    } catch (error) {
      console.error("Error saving filter options: ", error);
    }
  }

  const handlePhylloSearch = async (
    mappedFilterOptions,
    isNextPage = false,
    offset = 0
  ) => {
    const contactSearchLimit = parseInt(
      profile.account_limits.contact_search_limit,
      10
    );
    const contactsSearched = profile.contacts_searched;

    // Check if contact search limit has been reached
    if (contactsSearched >= contactSearchLimit) {
      toast.error(
        `You have reached the limit of ${contactSearchLimit} searches.`
      );
      return;
    }

    // Ensure the limit and offset follow the constraint: limit + offset <= 500
    if (searchLimit + offset > 500) {
      toast.error("Limit + Offset should be less than or equal to 500.");
      return;
    }

    console.log(mappedFilterOptions);

    let sortByField;

    switch (selectedSortOption) {
      case "Average Likes":
        sortByField = "AVERAGE_LIKES";
        break;
      case "Followers":
        sortByField = "FOLLOWER_COUNT";
        break;
      case "Engagement":
        sortByField = "ENGAGEMENT_RATE";
        break;
      default:
        sortByField = "AVERAGE_LIKES";
    }

    mappedFilterOptions.sort_by = {
      field: sortByField,
      order: "DESCENDING",
    };

    // Set default creator location if not specified
    if (!mappedFilterOptions.creator_locations) {
      mappedFilterOptions.creator_locations = [
        "cb8c4bd2-7661-4761-971a-c27322e2f209",
      ];
    }

    // Add pagination parameters
    console.log("searchLimit", searchLimit);
    console.log("searchOffset", offset);
    mappedFilterOptions.limit = searchLimit;
    mappedFilterOptions.offset = offset;

    setLoading(true);
    const endpoint =
      "https://brandlink-24432.uc.r.appspot.com/phyllo/creator-search";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedFilterOptions),
      });

      if (response.status === 404) {
        toast.error("No Profiles Found. Modify Search Filters.");
        return;
      }

      const data = await response.json();
      console.log(data);

      if (Array.isArray(data.data) && data.data.length > 0) {
        if (isNextPage) {
          const newSearchData = [...phylloCreatorData, ...data.data];

          saveRecentSearch(newSearchData);

          setPhylloCreatorData(newSearchData);
        } else {
          saveRecentSearch(data.data);
          setPhylloCreatorData(data.data);
        }

        updateContactsFetchedCount(data.data.length);
        updateLastResultsCount(data.metadata.total_results);
      } else {
        toast.error("Error Searching Database!");
      }
    } catch (err) {
      toast.error("Error Searching Database!");
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   handlePhylloSearch();
  // }, []);

  const fetchProfileAnalytics = async (identifier, work_platform_id) => {
    const endpoint =
      "https://brandlink-24432.uc.r.appspot.com/phyllo/fetch-creator-analytics";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, work_platform_id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  async function searchCollection(searchTerm, collectionName) {
    const coll = collection(db, collectionName); // Use collectionName as the argument
    const q = query(
      coll,
      where("name", ">=", searchTerm),
      where("name", "<=", searchTerm + "\uf8ff"),
      orderBy("name"), // Order by the 'name' field alphabetically
      limit(50) // Limiting the results to 50
    );

    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (collectionName === "locations") {
        results.push({
          ...docData,
          code: doc.id,
        });
      } else {
        results.push(docData);
      }
    });

    console.log(results);
    return results;
  }

  function testFilterMap(filterOptions) {
    console.log(mapFilters(filterOptions));
  }

  async function fetchAndSaveLocations() {
    const options = {
      method: "GET",
      url: "https://api.insightiq.ai/v1/social/creators/dictionary/locations",
      headers: {
        Accept: "application/json",
        Authorization:
          "Basic NmNhZGY4MGYtNzAzNS00ZDY2LTgxNzktMWIxZDZkOTU1MTE3OjZkZDQ5YmJmLTRmNzAtNGFiOC04MDI3LWY0MTlkNWJkMDZhNw==",
      },
      params: {
        limit: 100,
        offset: 0,
      },
    };

    try {
      while (true) {
        console.log(`Fetching locations with offset: ${options.params.offset}`);

        console.log("Request options:", options);

        const { data } = await axios.request(options);

        // Log the data received from the API
        console.log("Data received:", data);

        if (!data.data || data.data.length === 0) {
          console.log("No more locations to fetch.");
          break;
        }

        console.log(`Fetched ${data.data.length} locations.`);

        for (const location of data.data) {
          const locationDoc = {
            ...location,
            display_name: location.name,
            name: location.name.toLowerCase(),
          };

          console.log(`Saving location with id: ${location.id}`);

          // Log the document to be saved
          console.log("Location document:", locationDoc);

          try {
            await setDoc(
              doc(collection(db, "locations"), location.id),
              locationDoc
            );
            console.log(`Successfully saved location with id: ${location.id}`);
          } catch (docError) {
            console.error(
              `Error saving location with id: ${location.id}`,
              docError
            );
          }
        }

        options.params.offset += 100;
      }

      console.log("All locations have been added to Firebase.");
    } catch (error) {
      console.error("Error fetching or saving locations:", error);
    }
  }

  return (
    <PhyllloContext.Provider
      value={{
        saveFilterOptions,
        saveRecentSearch,
        handlePhylloSearch,
        phylloCreatorData,
        fetchProfileAnalytics,
        searchCollection,
        testFilterMap,
        loading,
        error,
        sortByDropdownOptions,
        selectedSortOption,
        setSelectedSortOption,
        searchLimit,
        searchOffset,
        setSearchLimit,
        setSearchOffset,
      }}
    >
      {children}
    </PhyllloContext.Provider>
  );
};
