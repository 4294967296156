import React, { PureComponent } from "react";
import styles from "./charts.module.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default class Example extends PureComponent {
  static demoUrl =
    "https://codesandbox.io/p/sandbox/line-chart-width-xaxis-padding-8v7952";

  render() {
    return (
      <LineChart
        width={600}
        height={150}
        data={data}
        className={styles.chart_shadow}
      >
        <CartesianGrid stroke="#eee" strokeDasharray="25 25" />
        {/* 
        //
 */}
        {/* <div className={styles.opacity}>

        </div>
        <XAxis dataKey="name" /> */}
        {/* <YAxis /> */}
        <Line type="monotone" dataKey="uv" stroke="rgba(0, 88, 220, 0.4)" />
        <Line type="monotone" dataKey="pv" stroke="rgba(0, 88, 220, 0.8)" />
      </LineChart>
    );
  }
}
