import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./profile_sections.module.css";

export default function PerformanceSection(props) {
  const TOP_CONTENT = props.profile.profile.top_contents;
  const profile = props.profile.profile;

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num;
    }
  }

  const CREATOR_PERFORMANCE = [
    {
      image: "/images/PerformanceIcons/FollowersIcon.png",
      title: "Followers",
      value: formatNumber(profile.follower_count),
    },
    {
      image: "/images/PerformanceIcons/EngagementIcon.png",
      title: "Engagement Rate",
      value: formatNumber(profile.engagement_rate),
    },
    {
      image: "/images/PerformanceIcons/SponsorIcon.png",
      title: "Sponsored Posts",
      value: formatNumber(profile.sponsored_posts_performance),
    },
    {
      image: "/images/PerformanceIcons/LikeIcon.png",
      title: "Average likes",
      value: formatNumber(profile.average_likes),
    },
    {
      image: "/images/PerformanceIcons/CommentIcon.png",
      title: "Average comments",
      value: formatNumber(profile.average_comments),
    },
    {
      image: "/images/PerformanceIcons/ViewIcon.png",
      title: "Average views",
      value: formatNumber(profile.average_views),
    },
    {
      image: "/images/PerformanceIcons/ViewIcon.png",
      title: "Average views",
      value: formatNumber(profile.average_reels_views),
    },
  ];

  function formatTimestamp(isoTimestamp) {
    const date = new Date(isoTimestamp);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[date.getUTCMonth()];
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
  }
  return (
    <div className={styles.section_layout}>
      <div className={styles.sub_section_layout}>
        <p className={styles.sub_section_title}>Analytics</p>
        <div className={styles.performance_layout}>
          {CREATOR_PERFORMANCE.map(
            (stat) =>
              stat.value !== null && (
                <div className={styles.performance_tile}>
                  <div className={styles.performance_text}>
                    <p className={styles.stat_value}>{stat.value}</p>
                    <p className={styles.stat_title}>{stat.title}</p>
                  </div>
                  <div className={styles.stat_icon_bg}>
                    <img
                      className={styles.stat_icon}
                      alt="icon"
                      src={stat.image}
                    ></img>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div className={styles.sub_section_layout}>
        <p className={styles.sub_section_title}>Top Content</p>
        <div className={styles.top_content_layout}>
          {TOP_CONTENT.map((content) => (
            <div className={styles.top_content_tile}>
              <div className={styles.top_content_top_info}>
                <div className={styles.content_post_info}>
                  <img
                    className={styles.profile_image}
                    alt="content"
                    src={profile.image_url}
                  ></img>
                  <p>{formatTimestamp(content.published_at)}</p>
                </div>
                <img
                  className={styles.platform_image}
                  alt="platform"
                  src="/images/social_icons/instagram.png"
                ></img>
              </div>
              <img
                className={styles.top_content_image}
                alt="content"
                src={content.thumbnail_url}
              ></img>
              <div className={styles.top_content_bottom_info}>
                <p className={styles.top_content_caption}>
                  {content.description}
                </p>
                <div className={styles.top_content_engagement}>
                  <img
                    className={styles.top_content_icons}
                    alt="content"
                    src="/images/PerformanceIcons/LikeIcon.png"
                  ></img>
                  <p className={styles.top_content_engagement_numbers}>
                    {formatNumber(content.engagement.like_count)}
                  </p>
                  <img
                    className={styles.top_content_icons}
                    alt="content"
                    src="/images/PerformanceIcons/CommentIcon.png"
                  ></img>
                  <p className={styles.top_content_engagement_numbers}>
                    {formatNumber(content.engagement.comment_count)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
