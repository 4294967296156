import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./creator_profile.module.css";
import { PhyllloContext } from "../../../context/PhylloContext";
import PerformanceSection from "./CreatorProfileSections/PerformanceSection";
import AudienceSection from "./CreatorProfileSections/AudienceSection";
import GrowthInterestSection from "./CreatorProfileSections/GrowthInterestSection";
import SimilarCreatorsSection from "./CreatorProfileSections/SimilarCreators";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../UniversalComponents/LoadingAnimation";
import formatLocation from "../../../utils/formatLocation";

export default function CreatorProfileComponent() {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const { id: userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [showInfoDiv, setShowInfoDiv] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll(".full_width").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect(); // Cleanup observer
  }, []);

  const mainLayoutRef = useRef(null);
  const performanceRef = useRef(null);
  const growthInterestRef = useRef(null);
  const audienceRef = useRef(null);
  const similarCreators = useRef(null);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (mainLayoutRef.current && mainLayoutRef.current.scrollTop > 300) {
        setShowInfoDiv(true);
      } else {
        setShowInfoDiv(false);
      }
    };

    // Check if mainLayoutRef.current is not null before adding the event listener
    if (mainLayoutRef.current) {
      mainLayoutRef.current.addEventListener("scroll", handleScroll);
    }

    // Cleanup function
    return () => {
      // Check if mainLayoutRef.current is not null before removing the event listener
      if (mainLayoutRef.current) {
        mainLayoutRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []); // Dependency array is empty

  console.log(userId);
  const { fetchProfileAnalytics } = useContext(PhyllloContext);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchProfileAnalytics(
          decrypt(userId),
          "9bb8913b-ddd9-430b-a66a-d74d846e6c66"
        );
        console.log(data);
        setProfile(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, [userId]);

  function decrypt(encodedUrl) {
    return atob(encodedUrl);
  }

  function goBack() {
    navigate(-1);
  }

  return (
    <div className={styles.main_layout} ref={mainLayoutRef}>
      {showInfoDiv && (
        <div className={styles.scroll_header}>
          <img
            alt="back"
            // onClick={goBack}
            src="/BackArrowFull.png"
            className={styles.back_icon}
            onClick={goBack}
          ></img>
          <div className={styles.profile_controls}>
            <div className={styles.gray_button}>Share</div>
            <div className={styles.gray_button}>Contact</div>
            <div className={styles.blue_button}>Connect</div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className={styles.loading_screen}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <img
              alt="back"
              src="/BackArrowFull.png"
              className={styles.back_icon}
              onClick={goBack}
            ></img>
            <div className={styles.profile_controls}>
              <div className={styles.gray_button}>Share</div>
              <div className={styles.gray_button}>Contact</div>
              <div className={styles.blue_button}>Connect</div>
            </div>
          </div>
          <div className={styles.profile_basic_info}>
            <img
              alt="profile"
              src={profile.profile.image_url}
              className={styles.profile_picture}
            ></img>
            <div className={styles.profile_info}>
              <p className={styles.profile_name}>{profile.profile.full_name}</p>
              <p className={styles.profile_username}>
                @{profile.profile.platform_username}
              </p>
              <p className={styles.profile_bio}>
                {profile.profile.introduction}
              </p>
            </div>
          </div>
          <BasicInfo />
          <div className={styles.profile_sections}>
            <div
              className={styles.profile_section_option_selected}
              onClick={() => scrollToSection(performanceRef)}
            >
              Performance
            </div>
            <div
              className={styles.profile_section_option}
              onClick={() => scrollToSection(growthInterestRef)}
            >
              Growth & Interests
            </div>
            <div
              className={styles.profile_section_option}
              onClick={() => scrollToSection(audienceRef)}
            >
              Audience
            </div>
            <div
              className={styles.profile_section_option}
              onClick={() => scrollToSection(similarCreators)}
            >
              Similar Creators
            </div>
          </div>
          <div className={styles.full_width} ref={performanceRef}>
            <p className={styles.section_title}>Performance</p>
            <PerformanceSection profile={profile} />
          </div>
          <div className={styles.full_width} ref={growthInterestRef}>
            <p className={styles.section_title}>Growth & Interest</p>
            <GrowthInterestSection profile={profile} />
          </div>
          <div className={styles.full_width} ref={audienceRef}>
            <p className={styles.section_title}>Audience</p>
            <AudienceSection profile={profile} />
          </div>
          {profile.profile.lookalikes && (
            <div className={styles.full_width} ref={similarCreators}>
              <p className={styles.section_title}>Similar Creators</p>
              <SimilarCreatorsSection profile={profile} />
            </div>
          )}
        </>
      )}
    </div>
  );

  function BasicInfo() {
    return (
      <div className={styles.info_section}>
        {formatLocation(profile.profile.location) !== false && (
          <div className={styles.profile_basic_info}>
            <div className={styles.icon_background}>
              <img
                alt="profile"
                src="/images/general_icons/LocationIcon.png"
                className={styles.info_icon}
              ></img>
            </div>
            <div className={styles.profile_info}>
              <p className={styles.top_info_title}>Location</p>
              <p className={styles.top_info_subtitle}>
                {formatLocation(profile.profile.location)}
              </p>
            </div>
          </div>
        )}
        {profile.profile.age_group && (
          <div className={styles.profile_basic_info}>
            <div className={styles.icon_background}>
              <img
                alt="profile"
                src="/images/general_icons/AgeIcon.png"
                className={styles.info_icon}
              ></img>
            </div>
            <div className={styles.profile_info}>
              <p className={styles.top_info_title}>Age</p>
              <p className={styles.top_info_subtitle}>
                Between {profile.profile.age_group} years old
              </p>
            </div>
          </div>
        )}
        {profile.profile.language && (
          <div className={styles.profile_basic_info}>
            <div className={styles.icon_background}>
              <img
                alt="profile"
                src="/images/general_icons/LanguageIcon.png"
                className={styles.info_icon}
              ></img>
            </div>
            <div className={styles.profile_info}>
              <p className={styles.top_info_title}>Language</p>
              <p className={styles.top_info_subtitle}>
                {profile.profile.language}
              </p>
            </div>
          </div>
        )}
        {profile.profile.gender && (
          <div className={styles.profile_basic_info}>
            <div className={styles.icon_background}>
              <img
                alt="profile"
                src="/images/general_icons/GenderIcon.png"
                className={styles.info_icon}
              ></img>
            </div>
            <div className={styles.profile_info}>
              <p className={styles.top_info_title}>Gender</p>
              <p className={styles.top_info_subtitle}>
                {profile.profile.gender}
              </p>
            </div>
          </div>
        )}
        <div className={styles.profile_basic_info}>
          <div className={styles.icon_background}>
            <img
              alt="profile"
              src="/images/general_icons/TypeIcon.png"
              className={styles.info_icon}
            ></img>
          </div>
          <div className={styles.profile_info}>
            <p className={styles.top_info_title}>Account Type</p>
            <p className={styles.top_info_subtitle}>
              {profile.profile.platform_account_type}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
