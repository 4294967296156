import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./contacts.module.css";
import { AuthContext } from "../../../context/AuthContext";
import { PhyllloContext } from "../../../context/PhylloContext";
import CreatorTile from "../CreatorSearchComponents/CreatorTile";
import LoadingAnimation from "../UniversalComponents/LoadingAnimation";
import { mapFilters } from "../../../helpers/mapFilters";
import UpgradePlan from "../../PopUpComponents/UpgradePlan/UpgradePlan";
import SearchbarHeader from "../UniversalComponents/SearchbarHeader/SearchbarHeader";

import EmptyIcon from "../../../assets/SVG/EmptyIcon";

import { useNavigate } from "react-router-dom";

export default function ContactsComponent({
  addContactToNewCampaign,
  displayDefaults,
  title,
  type,
}) {
  const { profile, planType, fetchUserContacts } =
    useContext(AuthContext);

  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const sortByDropdownOptions = [
    "Date Added",
    "Followers",
    "Average Likes",
    "Engagement",
  ];
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortByDropdownOptions[0]
  );

  const [searchTerm, setSearchTerm] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [contacts, setContacts] = useState([]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchContacts() {
      if (profile?.uid) {
        setLoading(true);
        try {
          const fetchedContacts = await fetchUserContacts(
            profile.uid,
            searchTerm,
            selectedSortOption
          );
          console.log("fetchedContacts", fetchedContacts);
          setContacts(fetchedContacts);
        } catch (error) {
          console.error("Error fetching contacts:", error);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchContacts();
  }, [profile, searchTerm, selectedSortOption]);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  function showUpgradePlatformPopup() {
    setIsPopupOpen(true);
  }

  function exportCSV() {
    console.log("export!");
  }

  const [hoveredSection, setHoveredSection] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  function handleSearch() {
    navigate(`/social-search`);
  }

  const SortDropdown = () => (
    <div className={styles.sortDropdown}>
      {sortByDropdownOptions.map((option) => (
        <div
          key={option}
          className={
            option === selectedSortOption
              ? styles.sortDropdownItemClicked
              : styles.sortDropdownItem
          }
          onClick={() => handleDropdownSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );

  const handleDropdownSelect = async (option) => {
    console.log(option);
    setSelectedSortOption(option);
    setIsDropdownVisible(false);
    // await fetchUserContacts(option);
    // updateFilters();
  };

  const handleCreatorCheck = (index, isChecked) => {
    if (isChecked) {
      setSelectedCreators((prev) => [...prev, index]);
    } else {
      setSelectedCreators((prev) => prev.filter((id) => id !== index));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // If currently selected, unselect all
      setSelectedCreators([]);
    } else {
      // Select all creators by using their indices
      const allIndices = contacts.map((_, index) => index);
      setSelectedCreators(allIndices);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectAll(
      selectedCreators.length > 0 && selectedCreators.length === contacts.length
    );
  }, [selectedCreators, contacts]);

  function handleCheckboxClick(event) {
    event.stopPropagation();
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Your Saved Contacts</p>
      <SearchbarHeader
        create={true}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        actionFunction={handleSearch}
      />
      <UpgradePlan isOpen={isPopupOpen} onClose={closePopup} />

      {loading ? (
        <div className={styles.loading_layout}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className={styles.creator_list_controls}>
            <div className={styles.creator_list_left_controls}>
              <p className={styles.creator_found_title}>Contacts</p>

              {/* {selectedCreators.length !== 0 && (
                <div className={styles.connect_all_button}>Connect All</div>
              )} */}
            </div>
            <div className={styles.creator_list_right_controls}>
              <div>
                <p className={styles.creator_selected_text}>
                  {selectedCreators.length !== 0 &&
                    selectedCreators.length + " contacts selected"}
                </p>
              </div>
              {type !== "CampaignCreation" && (
                <div
                  className={`${styles.export_section} ${
                    selectedCreators.length !== 0
                      ? styles.export_section_active
                      : ""
                  }`}
                  onClick={() => {
                    if (planType === "Standard") {
                      showUpgradePlatformPopup();
                    } else {
                      exportCSV();
                    }
                  }}
                >
                  {planType === "Standard" && (
                    <img
                      className={styles.locked_icon}
                      src="/PasswordIcon.png"
                      alt="locked"
                    ></img>
                  )}
                  <p className={styles.export_text}>Export CSV</p>
                </div>
              )}

              <div className={styles.sort_section} onClick={toggleDropdown}>
                <img
                  className={styles.sort_icon}
                  src="/SortIcon.png"
                  alt="sort"
                ></img>
                <p className={styles.sort_text}>
                  Sort by
                  <span className={styles.chosen_sort_text}>
                    {" "}
                    {selectedSortOption}
                  </span>
                </p>
                <img
                  src="/images/general_icons/DownArrowIcon.png"
                  alt="dropdown"
                  className={`${styles.dropdown_arrow_sort} ${
                    isDropdownVisible ? styles.flipped : ""
                  }`}
                />
              </div>
              {isDropdownVisible && <SortDropdown />}
            </div>
          </div>
          {contacts !== [] && (
            <div className={styles.creator_list_fields}>
              <div className={styles.checkbox_field}>
                <input
                  type="checkbox"
                  className={styles.ui_checkbox}
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
              <div className={styles.creator_info}>
                <p>Account Info</p>
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Followers")}
                onMouseEnter={() => setHoveredSection("Followers")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Followers" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Followers
                </p>
                {selectedSortOption === "Followers" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Followers" &&
                  hoveredSection === "Followers" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Average Likes")}
                onMouseEnter={() => setHoveredSection("Average Likes")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Average Likes" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Average Likes
                </p>
                {selectedSortOption === "Average Likes" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Average Likes" &&
                  hoveredSection === "Average Likes" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>

              <div className={styles.connect_button_section}></div>
            </div>
          )}
          {contacts && contacts != 0 ? (
            <div className={styles.creator_list}>
              {contacts?.map((creator, index) => {
                return (
                  <CreatorTile
                    key={index}
                    isContactsPage={true}
                    creator={creator}
                    type={type}
                    isChecked={selectedCreators.includes(index)}
                    onCheck={handleCreatorCheck}
                    index={index}
                    addContactToNewCampaign={addContactToNewCampaign}
                    contacts={contacts}
                    setContacts={setContacts}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.no_contacts_layout}>
              <EmptyIcon />
              <p className={styles.empty_text}>You don't have any contacts!</p>
              <div
                className={styles.search_contacts_button}
                onClick={handleSearch}
              >
                Search for Contacts
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
