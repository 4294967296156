function convertToCSV(data) {
  if (!data || !data.length) return "";

  // Extract headers from the first object, excluding `external_id`
  const headers = Object.keys(data[0]).filter((key) => key !== "external_id");

  // Handle the contact_details separately to include as columns
  const contactDetailHeaders = data
    .flatMap((row) => row.contact_details.map((detail) => detail.type))
    .filter((value, index, self) => self.indexOf(value) === index); // Unique contact detail types

  // Define column order
  const standardHeaders = headers.slice(0, 2); // First two columns
  const remainingHeaders = headers.slice(2); // Remaining columns
  const allHeaders = [
    ...standardHeaders,
    ...contactDetailHeaders,
    ...remainingHeaders,
  ];

  // Create CSV rows
  const rows = [
    allHeaders.join(","), // Header row
    ...data.map((row) => {
      // Create a map of contact details for quick lookup
      const contactDetailMap = row.contact_details.reduce((acc, detail) => {
        acc[detail.type] = detail.value;
        return acc;
      }, {});

      return allHeaders
        .map((header) => {
          if (standardHeaders.includes(header)) {
            // Handle standard fields and complex objects
            const value = row[header];

            // Special handling for work_platform to only include the name
            if (header === "work_platform" && typeof value === "object") {
              return `"${String(value.name || "").replace(/"/g, '""')}"`;
            }

            if (typeof value === "object") {
              // Convert object/array to JSON string for other objects
              return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
            } else {
              // Handle normal values
              return `"${String(value || "").replace(/"/g, '""')}"`;
            }
          } else if (contactDetailHeaders.includes(header)) {
            // Handle contact details
            return `"${String(contactDetailMap[header] || "").replace(
              /"/g,
              '""'
            )}"`;
          } else {
            // Handle remaining fields
            return `"${String(row[header] || "").replace(/"/g, '""')}"`;
          }
        })
        .join(",");
    }),
  ];

  // Join rows with newline characters
  return rows.join("\n");
}

// Exports data to a CSV file
export default function exportCSV(data, filename = "creator-data.csv") {
  const csv = convertToCSV(data);
  if (!csv) return;

  // Create a Blob from the CSV data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    // Feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Clean up the URL object
  }
}
