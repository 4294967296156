import React from "react";
import { useContext, useState } from "react";
import styles from "./feedback.module.css";
import { AuthContext } from "../../../context/AuthContext";

function FeedbackComponent() {
  const [feedback, setFeedback] = useState("");
  const { profile, postFeedback } = useContext(AuthContext);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSendFeedback = async () => {
    if (feedback.trim() === "") {
      return;
    }
    try {
      await postFeedback(profile.uid, feedback);
      setFeedback("");
    } catch (error) {}
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Feedback</p>
      <p className={styles.page_subtitle}>
        Collaborate with Us to Enhance Our Services Through Your Feedback and
        Insights
      </p>
      <textarea
        className={styles.feedbackBox}
        value={feedback}
        onChange={handleFeedbackChange}
      ></textarea>
      <div
        className={styles.send_button}
        onClick={handleSendFeedback} // Handle the send button click
      >
        <p>Send</p>
      </div>
    </div>
  );
}

export default FeedbackComponent;
