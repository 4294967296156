import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
  where,
  orderBy,
  limit,
  increment,
} from "firebase/firestore";

export const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedTrail, setSelectedTrail] = useState(null);
  const { profile, setProfile } = useContext(AuthContext);

  const markThreadAsRead = async (threadId) => {
    try {
      const emailThreadRef = doc(
        db,
        `accounts/${profile.uid}/email_threads/${threadId}`
      );

      await updateDoc(emailThreadRef, {
        read: true,
      });

      const userRef = doc(db, `accounts/${profile.uid}`);
      await updateDoc(userRef, {
        unread_threads: increment(-1),
      });
    } catch (error) {
      console.error("Error marking thread as read:", error);
      throw error;
    }
  };

  async function fetchEmailsByAccount(accountID, currentInboxType) {
    const emailThreadsPath = `accounts/${profile.uid}/email_threads`;
    console.log("id", accountID);
    const repliedValue = currentInboxType === "Inbox";

    const emailThreadsQuery = query(
      collection(db, emailThreadsPath),
      where("account_id", "==", accountID),
      where("replied", "==", repliedValue),
      orderBy("most_recent_message", "desc"),
      limit(20)
    );
    try {
      const querySnapshot = await getDocs(emailThreadsQuery);

      const emails = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const emailsPath = `${emailThreadsPath}/${doc.id}/emails`;

          const emailsQuery = query(
            collection(db, emailsPath),
            orderBy("date", "desc"),
            limit(20)
          );

          const emailsSnapshot = await getDocs(emailsQuery);

          // Fetch rest of the information from the thread document
          const threadDoc = await getDoc(doc.ref);

          const reversedEmails = emailsSnapshot.docs
            .map((email) => email.data())
            .reverse();

          return {
            id: doc.id,
            threadInfo: threadDoc.data(),
            emails: reversedEmails,
          };
        })
      );

      return emails;
    } catch (error) {
      console.error("Error fetching email threads:", error);
      throw error;
    }
  }

  // async function fetchEmails(accountId) {
  //   const queryParams = new URLSearchParams({
  //     accountId: accountId,
  //   });

  //   const url = `https://brandlink-24432.uc.r.appspot.com/fetch-emails?${queryParams.toString()}`;

  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await axios.get(url, config);
  //     console.log(response.data);
  //     return response.data.items;
  //   } catch (error) {
  //     console.error("Error fetching emails:", error);
  //     throw error;
  //   }
  // }

  async function createEmail(
    accountId,
    to,
    subject,
    body,
    cc = [],
    contact = null
  ) {
    const url = `https://brandlink-24432.uc.r.appspot.com/email/create-email?accountId=${accountId}`;

    const emailData = {
      userId: profile.uid,
      to: to.map((recipient) => ({
        display_name: recipient.display_name,
        identifier: recipient.identifier,
      })),
      subject: subject,
      body: body,
      contact: contact,
    };

    if (cc.length > 0) {
      emailData.cc = cc.map((recipient) => ({
        display_name: recipient.display_name,
        identifier: recipient.identifier,
      }));
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(url, emailData, config);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  }

  async function replyEmail(accountId, replyId, to, subject, body, cc = []) {
    const url = `https://brandlink-24432.uc.r.appspot.com/email/reply-email?accountId=${accountId}`;

    const emailData = {
      to: to.map((recipient) => ({
        display_name: recipient.display_name,
        identifier: recipient.identifier,
      })),
      subject: subject,
      body: body,
      replyId: replyId,
    };

    if (cc.length > 0) {
      emailData.cc = cc.map((recipient) => ({
        display_name: recipient.display_name,
        identifier: recipient.identifier,
      }));
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(url, emailData, config);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  }

  // async function fetchMessages(accountId) {
  //   const queryParams = new URLSearchParams({
  //     accountId: accountId,
  //   });

  //   const url = `https://brandlink-24432.uc.r.appspot.com/fetch-messages?${queryParams.toString()}`;

  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const response = await axios.get(url, config);
  //     console.log(response.data);
  //     return response.data.items;
  //   } catch (error) {
  //     console.error("Error fetching emails:", error);
  //     throw error;
  //   }
  // }

  async function sendTransactionalEmail(to, subject, text, html) {
    const apiUrl =
      "https://brandlink-24432.uc.r.appspot.com/send-transactional-email";
    const payload = {
      to,
      subject,
      text,
      html,
    };

    try {
      const response = await axios.post(apiUrl, payload);
      console.log("Success:", response.data);
      alert("Email sent successfully!");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      alert(
        "Failed to send email: " +
          (error.response ? error.response.data.message : error.message)
      );
    }
  }

  async function disconnectEmailAccount(accountId) {
    const userId = profile.uid;
    const apiUrl =
      "https://brandlink-24432.uc.r.appspot.com/email/delete-email-account";

    const payload = {
      userId,
      accountId,
    };

    try {
      const response = await axios.post(apiUrl, payload);
      console.log("Email Account Disconnected:", response.data);

      const updatedConnectedEmails = profile.connected_emails.filter(
        (id) => id !== accountId
      );
      const updatedConnectedAccounts = profile.connected_accounts.filter(
        (account) => account.id !== accountId
      );

      setProfile((prevProfile) => ({
        ...prevProfile,
        connected_emails: updatedConnectedEmails,
        connected_accounts: updatedConnectedAccounts,
      }));
    } catch (error) {
      console.error("Failed to disconnect email account:", error);
      throw error;
    }
  }

  // useEffect(() => {
  //   const options = {
  //     include_headers: true, // Pass as a string
  //   };

  //   fetchSingleEmail("8KZUIfnzUwGaX0aYlM5lIw", options)
  //     .then((data) => {
  //       // Handle the data here (e.g., display emails in the UI)
  //       console.log("HEADERS", data);
  //       // setEmails(data);
  //     })
  //     .catch((error) => {
  //       // Handle errors here (e.g., show error message)
  //       console.error(error);
  //     });
  // }, []);

  // const fetchSingleEmail = async (emailId, options) => {
  //   try {
  //     const response = await axios.get(
  //       "https://brandlink-24432.uc.r.appspot.com/fetch-single-email",
  //       {
  //         params: {
  //           emailId,
  //           options: JSON.stringify(options), // Convert options object to JSON string
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching email:", error);
  //     throw new Error("Failed to fetch email");
  //   }
  // };

  return (
    <EmailContext.Provider
      value={{
        emails,
        setEmails,
        selectedTrail,
        setSelectedTrail,
        loading,
        error,
        // fetchEmails,
        // fetchMessages,
        replyEmail,
        createEmail,
        fetchEmailsByAccount,
        markThreadAsRead,
        sendTransactionalEmail,
        disconnectEmailAccount,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

//sendgrid recovery CWF92EJ4UVW7ME366XRXM7QA
