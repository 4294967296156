import { phyllo_filter_options } from "../components/PortalComponents/CreatorSearchComponents/phyllo_filter_options";

export function mapFilters(uiFilters) {
  let apiOptions = {}; // Initialize an empty object to store the API options
  console.log(uiFilters);
  for (const category in uiFilters) {
    const filters = uiFilters[category];

    for (const filterKey in filters) {
      const filter = filters[filterKey];

      if (filter.value) {
        if (filter.title === "Platform") {
          apiOptions["work_platform_id"] = filter.id;
        } else if (filter.title === "Followers") {
          apiOptions["follower_count"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Subscribers") {
          apiOptions["subscriber_count"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Email Preference") {
          apiOptions["specific_contact_details"] = [
            {
              type: "EMAIL",
              preference: filter.value ? "MUST_HAVE" : "SHOULD_HAVE",
            },
          ];
        } else if (filter.title === "Amount of Content") {
          apiOptions["content_count"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Audience Gender") {
          apiOptions["audience_gender"] = formatValues(
            filter.value,
            filter.sliderValue
          );
        } else if (filter.title === "Gender") {
          apiOptions["creator_gender"] = filter.value;
        } else if (filter.title === "Age") {
          apiOptions["audience_age"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Audience Age") {
          apiOptions["creator_age"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Keyword") {
          apiOptions["description_keywords"] = filter.value;
        } else if (filter.title === "Verified") {
          apiOptions["is_verified"] = filter.value === "True";
        } else if (filter.title === "Most Recent Post") {
          const currentDate = new Date();
          let dateThreshold;
          switch (filter.value) {
            case "<1 Month":
              dateThreshold = new Date(
                currentDate.setMonth(currentDate.getMonth() - 1)
              );
              break;
            case "<3 Month":
              dateThreshold = new Date(
                currentDate.setMonth(currentDate.getMonth() - 3)
              );
              break;
            case "<6 Month":
              dateThreshold = new Date(
                currentDate.setMonth(currentDate.getMonth() - 6)
              );
              break;
            default:
              break;
          }
          if (dateThreshold) {
            apiOptions["last_post_timestamp"] = dateThreshold.toISOString();
          }
        } else if (filter.title === "Audience Language") {
          apiOptions["audience_language"] = [
            formatCodePercent(filter.value[0].code, filter.sliderValue),
          ];
        } else if (filter.title === "Creator Language") {
          const formattedValue = {
            code: filter.value[0].code,
          };
          apiOptions["creator_language"] = formattedValue;
        } else if (filter.title === "Audience Interests") {
          apiOptions["audience_interests"] = formatDisplayNames(filter);
        } else if (filter.title === "Creator Interests") {
          apiOptions["creator_interests"] = formatDisplayNames(filter);
        } else if (filter.title === "Audience Brand Affinity") {
          apiOptions["audience_brand_affinities"] = formatDisplayNames(filter);
        } else if (filter.title === "Brand Affinity") {
          apiOptions["creator_brand_affinities"] = formatDisplayNames(filter);
        } else if (filter.title === "Average Likes") {
          apiOptions["average_likes"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Average Views") {
          //NOT ADDED
          apiOptions["average_views"] = formatMinMaxFilter(filter.value);
        } else if (filter.title === "Engagement Rate") {
          apiOptions["engagement_rate"] = filter.value;
        } else if (filter.title === "Has Sponsored") {
          apiOptions["has_sponsored_posts"] = filter.value === "True";
        } else if (filter.title === "Reel Views") {
          const reel_views = formatMinMaxFilter(filter.value);
          apiOptions["instagram_options"] =
            apiOptions["instagram_options"] || {};
          apiOptions["instagram_options"]["reel_views"] = reel_views;
        } else if (filter.title === "Audience Location") {
          apiOptions["audience_locations"] = [
            {
              location_id: formatCodes(filter)[0],
              percentage_value: filter.sliderValue,
              operator: "GT",
            },
          ];
        } else if (filter.title === "Creator Location") {
          apiOptions["creator_locations"] = formatCodes(filter);
        } else if (filter.title === "Bio Phrase") {
          //NOT ADDED
          apiOptions["bio_phrase"] = filter.value;
        } else if (filter.title === "Hashtags") {
          apiOptions["hashtags"] = filter.value.map((hashtag) => ({
            name: hashtag.replace(/^#+/, ""),
          }));
        } else if (filter.title === "Mentions") {
          apiOptions["mentions"] = deletePrefix(filter);
        } else if (filter.title === "Topics") {
          apiOptions["topic_relevance"] = {
            name: filter.value.map((topic) => `#${topic}`),
            weight: 0.5,
            threshold: 0.5,
          };
        } else if (filter.title === "Account Type") {
          apiOptions["platform_account_type"] = filter.value.toUpperCase();
        } else if (filter.title === "Audience Lookalikes") {
          //apiOptions["audience_lookalikes"] = filter.value
          //FIX THIS
          //NOT ADDED
        } else if (filter.title === "Creator Lookalikes") {
          //apiOptions["creator_lookalikes"] = filter.value
          //FIX THIS
          //NOT ADDED
        }
      }
    }
  }

  return apiOptions;
}
//NOTES
//ADD KEYWORDS AND CONTACT DETAILS
//Average Views as well

// audience_lookalikes: "string",
// platform_account_type: "ANY",

// creator_lookalikes: "string",
// ],

function formatMinMaxFilter(values) {
  const [min, max] = values;
  const formattedValue = {
    min: min !== null ? min : 0,
    max: max !== null ? max : 0,
  };
  return formattedValue;
}

function formatValues(value, sliderValue) {
  const formattedValue = {
    type: value.toUpperCase(),
    operator: "GT",
    percentage_value: sliderValue,
  };
  return formattedValue;
}

function formatCodePercent(code, sliderValue) {
  const formattedValue = {
    code: code,
    percentage_value: sliderValue,
  };
  return formattedValue;
}

function formatDisplayNames(object) {
  return object.value.map((item) => item.display_name);
}

function formatCodes(object) {
  return object.value.map((item) => item.code);
}

function deletePrefix(object) {
  return object.value.map((item) => item.substring(1));
}
