import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAJZ1TCdkI5E-yH3-MUEulOkabZDke8BhM",
  authDomain: "brandlink-24432.firebaseapp.com",
  projectId: "brandlink-24432",
  storageBucket: "brandlink-24432.appspot.com",
  messagingSenderId: "526888963648",
  appId: "1:526888963648:web:72daf7836c91be993b19a5",
  measurementId: "G-Q8YFFYKLSD",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
