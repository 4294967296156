import React from "react";

const SavedIcon = ({
  width = 18,
  height = 18,
  color = "rgba(0, 0, 0, 1)",
  ...props
}) => (
  <svg fill="none" height={height} viewBox="0 0 14 14" width={width} {...props}>
    <clipPath id="clip0_1236_2396">
      <path d="m0 0h14v14h-14z"></path>
    </clipPath>
    <g clipPath="url(#clip0_1236_2396)">
      {/* Duplicate the path and slightly offset to simulate thickness */}
      <path
        d="m3 4c-.13261 0-.25979.05268-.35355.14645-.09377.09376-.14645.22094-.14645.35355v7.9129l2.6746-2.2925c.18725-.16052.46355-.16052.6508 0l2.6746 2.2925v-7.9129c0-.13261-.05268-.25979-.14645-.35355-.09376-.09377-.22094-.14645-.35355-.14645zm-1.06066-.56066c.2813-.2813.66284-.43934 1.06066-.43934h5c.39782 0 .77936.15804 1.06066.43934s.43934.66284.43934 1.06066v9c0 .1953-.11367.3727-.29107.4543-.1774.0815-.38607.0524-.53433-.0747l-3.1746-2.7211-3.1746 2.7211c-.14826.1271-.35693.1562-.53433.0747-.1774-.0816-.29107-.259-.29107-.4543v-9c0-.39782.15804-.77936.43934-1.06066z"
        fill={color}
      />
      <path
        d="m3 4c-.13261 0-.25979.05268-.35355.14645-.09377.09376-.14645.22094-.14645.35355v7.9129l2.6746-2.2925c.18725-.16052.46355-.16052.6508 0l2.6746 2.2925v-7.9129c0-.13261-.05268-.25979-.14645-.35355-.09376-.09377-.22094-.14645-.35355-.14645zm-1.06066-.56066c.2813-.2813.66284-.43934 1.06066-.43934h5c.39782 0 .77936.15804 1.06066.43934s.43934.66284.43934 1.06066v9c0 .1953-.11367.3727-.29107.4543-.1774.0815-.38607.0524-.53433-.0747l-3.1746-2.7211-3.1746 2.7211c-.14826.1271-.35693.1562-.53433.0747-.1774-.0816-.29107-.259-.29107-.4543v-9c0-.39782.15804-.77936.43934-1.06066z"
        fill={color}
        transform="translate(0.4, 0.4)" // Slight offset
      />
      <path
        d="m4.5.5c0-.276142.22386-.5.5-.5h6c.3978 0 .7794.158035 1.0607.43934.2813.281304.4393.66283.4393 1.06066v9c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-9c0-.13261-.0527-.25978-.1464-.35355-.0938-.09377-.221-.14645-.3536-.14645h-6c-.27614 0-.5-.223858-.5-.5z"
        fill={color}
      />
      <path
        d="m4.5.5c0-.276142.22386-.5.5-.5h6c.3978 0 .7794.158035 1.0607.43934.2813.281304.4393.66283.4393 1.06066v9c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-9c0-.13261-.0527-.25978-.1464-.35355-.0938-.09377-.221-.14645-.3536-.14645h-6c-.27614 0-.5-.223858-.5-.5z"
        fill={color}
        transform="translate(0.4, 0.4)" // Slight offset
      />
    </g>
  </svg>
);

export default SavedIcon;
