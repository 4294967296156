import DashboardIcon from "../../assets/SVG/SidebarIcons/DashboardIcon";
import SearchIcon from "../../assets/SVG/SidebarIcons/SearchIcon";
import CampaignIcon from "../../assets/SVG/SidebarIcons/CampaignIcon";
import InboxIcon from "../../assets/SVG/SidebarIcons/InboxIcon";
import ContactsIcon from "../../assets/SVG/SidebarIcons/ContactsIcon";
import SettingsIcon from "../../assets/SVG/SidebarIcons/SettingsIcon";
import SavedIcon from "../../assets/SVG/SidebarIcons/SavedIcon";

const main_menu = [
  // {
  //   id: 1,
  //   title: "Admin",
  //   icon_image: "/images/SidebarIcons/SidebarSettings.png",
  //   iconKey: "stroke",
  //   url: "/admin",
  //   isSelf: true,
  // },
  // {
  //   id: 1,
  //   title: "Dashboard",
  //   Icon: DashboardIcon,
  //   iconKey: "stroke",
  //   url: "/dashboard",
  //   isSelf: true,
  // },
  {
    id: 3,
    title: "Social Search",
    Icon: SearchIcon,
    iconKey: "stroke",
    url: "/social-search",
    isSelf: true,
  },
  // {
  //   id: 3,
  //   title: "Campaigns",
  //   Icon: CampaignIcon,
  //   iconKey: "stroke",
  //   url: "/campaigns",
  //   isSelf: true,
  //   coming_soon: true,
  // },
  {
    id: 5,
    title: "Saved",
    Icon: SavedIcon,
    iconKey: "stroke",
    url: "/saved",
    isSelf: true,
  },
  {
    id: 2,
    title: "Your Inboxes",
    Icon: InboxIcon,
    iconKey: "stroke",
    url: "/inbox",
    isSelf: true,
    coming_soon: true,
  },
  // {
  //   id: 2,
  //   title: "Sequences",
  //   icon_image: "/images/SidebarIcons/SidebarSchedule.png",
  //   iconKey: "stroke",
  //   url: "/",
  //   isSelf: true,
  //   coming_soon: true,
  // },
  // {
  //   id: 2,
  //   title: "AIContent",
  //   icon_image: "/images/SidebarIcons/SidebarAI.png",
  //   iconKey: "stroke",
  //   url: "/",
  //   isSelf: true,
  //   coming_soon: true,
  // },

  {
    id: 6,
    title: "Settings",
    Icon: SettingsIcon,
    iconKey: "stroke",
    url: "/settings",
    isSelf: true,
  },
  // {
  //   id: 7,
  //   title: "Feedback",
  //   icon_image: "/images/SidebarIcons/SidebarFeedback.png",
  //   iconKey: "stroke",
  //   url: "/feedback",
  //   isSelf: true,
  // },
];

export default main_menu;
