import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { PaymentContext } from "../../../context/PaymentContext";
import { useNavigate } from "react-router";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";

const ChoosePlan = ({
  onNext,
  onBack,
  handleInputChange,
  handleMultipleInputChanges,
  formData,
}) => {
  const { productPlans, createStripeCustomer } = useContext(PaymentContext);
  //   const [defaultPrice, setDefaultPrice] = useState(null);

  const findDefaultPrice = (productPlans, planName = "Lite Plan") => {
    for (let product of productPlans) {
      if (product.name === planName) {
        return product.default_price; 
      }
    }
    return null;
  };

  useEffect(() => {
    const priceId = findDefaultPrice(productPlans, "Lite Plan");
    handleInputChange("subscription_price", priceId);
  }, [productPlans]);

  const canContinue = formData.subscription_plan;

  const [loading, setLoading] = useState("");

  //   async function handleNextPage() {
  //     onNext();
  //   }

  async function handleNextPage() {
    setLoading(true);
    try {
      const customer = await createStripeCustomer(formData);
      handleInputChange("stripe_id", customer.id);
      onNext();
      console.log(customer);
    } catch (error) {
      console.error("Registration error:", error);
    } finally {
      setLoading(false);
    }
  }

  const frequency_options = [
    { key: "monthly", name: "Monthly" },
    { key: "6_month", name: "6 Month" },
    { key: "yearly", name: "Yearly" },
  ];
  const [frequency, setFrequency] = useState("monthly");

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  console.log(productPlans);

  return (
    <div className={styles.sidebar}>
      <img
        alt="logo"
        src="/BrandLinkLogoBlue.png"
        className={styles.logo_icon}
      ></img>
      <p className={styles.plan_page_title}>Choose Plan</p>
      {/* <p className={styles.page_subtitle}>Pick the plan that best suits you</p> */}
      <div className={styles.frequency_options}>
        <div className={styles.radio_inputs}>
          {frequency_options.map((option) => (
            <label className={styles.radio} key={option.key}>
              <input
                type="radio"
                name="frequency"
                value={option.key}
                onChange={handleFrequencyChange}
                checked={frequency === option.key}
              />
              <span className={styles.name}>{option.name}</span>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.product_plans}>
        {productPlans?.map((plan, index) => (
          <ProductPlanTile
            plan={plan}
            frequency={frequency}
            selectedPlan={formData.subscription_price}
            handleInputChange={handleInputChange}
            handleMultipleInputChanges={handleMultipleInputChanges}
          />
        ))}
      </div>
      <div className={styles.spacer}></div>
      {canContinue ? (
        <div className={styles.page_button} onClick={handleNextPage}>
          {loading ? (
            <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
          ) : (
            <p className={styles.join_button_text}>Continue</p>
          )}
        </div>
      ) : (
        <div className={styles.page_button_inactive}>
          <p className={styles.join_button_text}>Continue</p>
        </div>
      )}
      <p className={styles.previous_step} onClick={onBack}>
        Previous Step
      </p>
    </div>
  );
};

function ProductPlanTile({
  plan,
  frequency,
  selectedPlan,
  handleInputChange,
  handleMultipleInputChanges,
}) {
  console.log(plan);

  const price = plan.prices.find((price) => price.nickname === frequency);

  const formattedPrice = price
    ? `$${(price.unit_amount / 100).toFixed(2)}`
    : "N/A";

  const isSelected = plan.prices.some((price) => price.id === selectedPlan);

  const priceStyle = isSelected
    ? styles.selected_product_plan_layout
    : styles.product_plan_layout;

  const selectCircleStyle = isSelected
    ? styles.plan_select_circle_selected
    : styles.plan_select_circle;

  function choosePrice() {
    handleMultipleInputChanges({
      subscription_price: price.id,
      subscription_plan: plan.id,
      account_limits: {
        mailbox_limit: plan.metadata.mailbox_limit,
        contact_search_limit: plan.metadata.contact_search_limit,
        ai_credit_limit: plan.metadata.ai_credit_limit,
      },
      allowed_platforms: {
        instagram: true,
        tiktok: plan.name !== "Lite Plan",
        youtube: plan.name !== "Lite Plan",
      },
    });
  }

  return (
    <div className={priceStyle} onClick={choosePrice}>
      {/* <div></div> */}
      <div className={styles.plan_information}>
        <p className={styles.plan_title}>
          {plan.name}
          <span className={styles.plan_title_price}>
            {formattedPrice}/month
          </span>
        </p>
        <div className={styles.feature_limits}>
          <p className={styles.marketing_feature_text}>
            {plan.metadata.mailbox_limit} Mailboxes
          </p>
          <p className={styles.marketing_feature_text}>
            {plan.metadata.contact_search_limit} Filtered Contacts
          </p>
          <p className={styles.marketing_feature_text}>
            {plan.metadata.ai_credit_limit} Ai Credits
          </p>
        </div>

        <div className={styles.data_sources}>
          <img
            alt="logo"
            src="/SocialMediaLogos/instagram.png"
            className={styles.data_icon}
          ></img>
          {plan.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/tiktok.png"
              className={styles.data_icon}
            ></img>
          )}
          {plan.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/youtube.png"
              className={styles.youtube_icon}
            ></img>
          )}
        </div>
        {/* <div className={styles.marketing_features}>
          {featureOptions.map((feature, index) => (
            <p className={styles.marketing_feature_text}>{feature}</p>
          ))}
        </div> */}
      </div>
      <div className={selectCircleStyle}>
        {isSelected && <div className={styles.plan_select_inner_circle}></div>}
      </div>
    </div>
  );
}

const featureOptions = [
  "3 Mailboxes",
  "20000 Ai Credits",
  "25000 Filtered Contacts",
];
// const SubscriptionPlans = (props) => {
//   return (
//     <div className={styles.subscription_plans}>
//       <stripe-pricing-table
//         pricing-table-id="prctbl_1OKYB2ITemniZ4CSJUxi9QR2"
//         publishable-key="pk_test_51OKXFWITemniZ4CSxEYQXF8W34MFKpPtbCtBb1fRYyXHBHxIHHN7q6WBxIIu81HlGRzDkBvAwIZJex5WvrwR1ZGp00StQdIcCo"
//         client-reference-id={props.userId}
//       ></stripe-pricing-table>
//     </div>
//   );
// };

export default ChoosePlan;
