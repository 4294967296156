import React, { useState, useContext, useEffect } from "react";
import styles from "./popups.module.css";
import Checkbox from "../../PortalComponents/UniversalComponents/Checkbox/Checkbox";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import CancelIcon from "../../../assets/SVG/CancelIcon";
import { EmailContext } from "../../../context/EmailContext";
import { toast } from "react-toastify";
import CreatorSearchComponent from "../../PortalComponents/CreatorSearchComponents/CreatorSearchComponent";
import ContactsComponent from "../../PortalComponents/ContactsComponents/ContactsComponent";

import SearchIcon from "../../../assets/SVG/SidebarIcons/SearchIcon";
import ContactsIcon from "../../../assets/SVG/SidebarIcons/ContactsIcon";
import ContactPersonIcon from "../../../assets/SVG/ContactPersonIcon";

const SearchFilterPopup = ({ isOpen, onClose, addContactToCampaign }) => {
  const [currentSearchType, setCurrentSearchType] = useState("Social Search");

  const modalClass = isOpen
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleSearchTypeChange = (type) => {
    setCurrentSearchType(type);
  };

  return (
    <div className={modalClass} onClick={onClose}>
      <div className={styles.modal_body} onClick={stopPropagation}>
        <div className={styles.close_icon} onClick={onClose}>
          <CancelIcon />
        </div>
        <div className={styles.search_tabs}>
          <div
            className={`${styles.search_option} ${
              currentSearchType === "Social Search"
                ? styles.search_option_selected
                : ""
            }`}
            onClick={() => handleSearchTypeChange("Social Search")}
          >
            <SearchIcon
              color={
                currentSearchType === "Social Search"
                  ? "rgba(0, 88, 220, 1)"
                  : "black"
              }
            />
            Social Search
          </div>
          <div
            className={`${styles.search_option} ${
              currentSearchType === "Your Contacts"
                ? styles.search_option_selected
                : ""
            }`}
            onClick={() => handleSearchTypeChange("Your Contacts")}
          >
            <ContactPersonIcon
              color={
                currentSearchType === "Your Contacts"
                  ? "rgba(0, 88, 220, 1)"
                  : "black"
              }
            />
            Your Contacts
          </div>
        </div>
        <div className={styles.modal_content}>
          {currentSearchType === "Social Search" ? (
            <CreatorSearchComponent
              displayDefaults={false}
              title="Add Campaign Contacts"
              type="CampaignCreation"
              addContactToNewCampaign={addContactToCampaign}
            />
          ) : (
            <ContactsComponent />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchFilterPopup;
