export default function formatLocation(locationObject) {
  if (!locationObject) {
    return false;
  }

  const { city, state, country } = locationObject;

  if (!city && !state && !country) {
    return false;
  }

  let locationParts = [];

  if (city) {
    locationParts.push(city);
  }
  if (state) {
    locationParts.push(state);
  }
  if (country) {
    locationParts.push(country);
  }

  return locationParts.join(", ");
}
