import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { PaymentProvider } from "./context/PaymentContext";
import { EmailProvider } from "./context/EmailContext";
import { PhylloProvider } from "./context/PhylloContext";
import { CampaignProvider } from "./context/CampaignContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthProvider>
      <PaymentProvider>
        <EmailProvider>
          <CampaignProvider>
            <PhylloProvider>
              <Routes />
            </PhylloProvider>
          </CampaignProvider>
        </EmailProvider>
      </PaymentProvider>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
