import React from "react";

const EmptyIcon = ({ width = 40, height = 40, color = "rgba(0, 0, 0, 1)" }) => (
  <svg
    id="fi_3276959"
    enable-background="new 0 0 128 128"
    width={width}
    height={height}
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m120.285 57.519c-1.825-2.686-4.628-4.164-7.891-4.164h-6.872v-10.275c0-5.276-4.292-9.568-9.568-9.568h-42.358l-4.283-9.575c-1.535-3.5-4.876-5.675-8.72-5.675h-24.888c-5.276 0-9.568 4.292-9.568 9.568 0 9.097-.028 18.194-.057 27.292l4 .012c.028-9.102.057-18.203.057-27.304 0-3.07 2.498-5.568 5.568-5.568h24.888c2.266 0 4.155 1.227 5.063 3.295l5.348 11.955h44.95c3.07 0 5.568 2.498 5.568 5.568v10.274h-68.441c-4.021 0-7.536 2.433-8.956 6.195l-14.128 37.259c.005-11.557.031-23.237.083-34.783l-4-.018c-.057 12.671-.085 25.511-.085 38.163 0 1.525.38 3.008 1.074 4.356l-.034.092.571.856c1.779 2.67 4.754 4.264 7.957 4.264h82.715c4.015 0 7.53-2.419 8.957-6.166l14.118-37.252c1.151-3.057.765-6.192-1.068-8.801zm-2.674 7.388-14.115 37.244c-.843 2.213-2.842 3.587-5.218 3.587h-82.715c-1.535 0-2.98-.631-4.021-1.723l16.324-43.051c.841-2.227 2.839-3.61 5.215-3.61h79.313c1.925 0 3.51.834 4.6 2.438 1.083 1.542 1.296 3.312.617 5.115z"></path>
  </svg>
);

export default EmptyIcon;
