import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import DashboardComponents from "../../components/PortalComponents/DashboardComponents/DashboardComponents";

const DashboardPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<DashboardComponents />}
        activeText="Dashboard"
      />
    </>
  );
};

export default DashboardPage;
