import React, { useState, useContext, useEffect } from "react";
import styles from "./stages.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import CancelIcon from "../../../../assets/SVG/CancelIcon";

export default function MessagingStrategy({ newCampaign, handleInputChange }) {
  const addNewMessage = () => {
    const updatedSequence = [
      ...newCampaign.campaign_message_sequence,
      {
        type: "email",
        status: "pending",
        from: null,
        subject: null,
        body: null,
        delay: 3,
      },
    ];
    handleInputChange("campaign_message_sequence", updatedSequence);
  };

  const handleMessageChange = (index, field, value) => {
    const updatedSequence = [...newCampaign.campaign_message_sequence];
    updatedSequence[index] = { ...updatedSequence[index], [field]: value };
    handleInputChange("campaign_message_sequence", updatedSequence);
  };

  const deleteEmail = (indexToRemove) => {
    const updatedSequence = newCampaign.campaign_message_sequence.filter(
      (_, index) => index !== indexToRemove
    );
    handleInputChange("campaign_message_sequence", updatedSequence);
  };

  return (
    <div className={styles.campaign_stage}>
      <p className={styles.account_targeting}>Messaging Strategy</p>
      <div className={styles.message_list_layout}>
        {/* <div className={styles.message_list_tracker}></div> */}
        <div className={styles.message_list}>
          {newCampaign.campaign_message_sequence.map((message, index) => (
            <React.Fragment key={index}>
              {!message.first && (
                <div className={styles.delay_layout}>
                  <div className={styles.line_div}></div>
                  <p className={styles.delay_time}>Wait {message.delay} Days</p>
                  <div className={styles.line_div}></div>
                </div>
              )}

              <EmailLayout
                index={index}
                message={message}
                onDelete={deleteEmail}
                updateMessage={handleMessageChange}
              />
            </React.Fragment>
          ))}

          <div className={styles.add_controls} onClick={addNewMessage}>
            <div className={styles.add_email}>Add Follow Up Email</div>
          </div>
        </div>
      </div>
    </div>
  );
}
function EmailLayout({ index, onDelete, message, updateMessage }) {
  const handleDeleteClick = () => {
    onDelete(index);
  };

  const handleFieldChange = (field, value) => {
    updateMessage(index, field, value);
  };

  const { profile } = useContext(AuthContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const activeEmail = profile.connected_accounts[0];

  useEffect(() => {
    if (activeEmail) {
      handleFieldChange("from", activeEmail);
    }
  }, [activeEmail]);

  const handleDropdownClick = (account) => {
    handleFieldChange("from", account);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.modal_content}>
      {index !== 0 && (
        <p className={styles.delete_button} onClick={handleDeleteClick}>
          <CancelIcon width={18} height={18} color="rgba(200, 0, 0)" />
        </p>
      )}
      <p className={styles.header_title}>New Email</p>
      {/* <div className={styles.email_info}>
        <p className={styles.email_info_title}>To:</p>
        {isEmailValid ? (
          <div
            className={styles.email_display}
            onClick={() => setIsEmailValid(false)}
          >
            {to}
          </div>
        ) : (
          <input
            placeholder="Search or add a contact"
            className={styles.email_info_content}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            onBlur={handleBlurOrEnter}
            onKeyPress={handleKeyPress}
          />
        )}
      </div>

      <div className={styles.section_line_divider}></div> */}
      <div className={styles.email_info}>
        <p className={styles.email_info_title}>From:</p>
        <div
          className={styles.email_from}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {message?.from?.name}
          <img
            src="/images/general_icons/DownArrowIcon.png"
            alt="down"
            className={`${styles.down_icon} ${
              isDropdownOpen ? styles.rotate_180 : ""
            }`}
          ></img>
          {isDropdownOpen && (
            <div className={styles.dropdown}>
              {profile.connected_accounts.map((account, index) => (
                <div
                  key={index}
                  className={styles.dropdown_item}
                  onClick={() => handleDropdownClick(account)}
                >
                  {account.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.section_line_divider}></div>
      <div className={styles.email_info}>
        <p className={styles.email_info_title}>Subject:</p>
        <input
          value={message.subject}
          onChange={(e) => handleFieldChange("subject", e.target.value)}
          className={styles.email_info_content}
          placeholder="Add a subject"
        />
      </div>
      <div className={styles.section_line_divider}></div>
      <div className={styles.email_body}>
        <textarea
          value={message.body}
          onChange={(e) => handleFieldChange("body", e.target.value)}
          className={styles.email_body_area}
          placeholder="Write your message here..."
        ></textarea>
      </div>
    </div>
  );
}
