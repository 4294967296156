import React from "react";

const ConfigurationIcon = ({
  width = 17,
  height = 17,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 4.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_563608"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M478.08,200.17l-28.46-5c-3.573-11.424-8.154-22.507-13.69-33.12l16.58-23.63c11.454-16.293,9.521-38.465-4.58-52.53
			L426.1,64.06c-14.065-14.101-36.237-16.034-52.53-4.58l-23.64,16.58c-10.613-5.536-21.696-10.117-33.12-13.69l-5-28.46
			C308.368,14.306,291.344,0.006,271.44,0h-30.88c-19.92-0.023-36.964,14.295-40.38,33.92l-5,28.45
			c-11.424,3.572-22.508,8.153-33.12,13.69l-23.63-16.58c-16.293-11.454-38.465-9.521-52.53,4.58L64.06,85.9
			c-14.101,14.065-16.034,36.237-4.58,52.53l16.57,23.63c-5.536,10.613-10.117,21.696-13.69,33.12l-28.45,5
			C14.289,203.6-0.023,220.644,0,240.56v30.88c0.002,19.911,14.308,36.942,33.92,40.38l28.45,5
			c3.572,11.424,8.153,22.508,13.69,33.12l-16.58,23.63c-11.454,16.293-9.521,38.465,4.58,52.53l21.84,21.84
			c14.065,14.101,36.237,16.034,52.53,4.58l23.63-16.57c10.612,5.537,21.696,10.119,33.12,13.69l5,28.46
			c3.424,19.617,20.467,33.924,40.38,33.9h30.88c19.916,0.023,36.96-14.289,40.38-33.91c1.243-7.072-3.483-13.812-10.555-15.055
			c-7.072-1.243-13.812,3.483-15.055,10.555c-1.258,7.174-7.487,12.408-14.77,12.41h-30.88c-7.287,0.003-13.522-5.232-14.78-12.41
			l-6.39-36.43c-0.866-4.967-4.526-8.985-9.39-10.31c-14.78-3.955-28.981-9.825-42.24-17.46c-4.388-2.542-9.854-2.3-14,0.62
			l-30.25,21.22c-5.965,4.181-14.071,3.472-19.22-1.68l-21.84-21.84c-5.152-5.149-5.861-13.255-1.68-19.22L102,358.25
			c2.92-4.146,3.162-9.612,0.62-14c-7.641-13.262-13.518-27.466-17.48-42.25c-1.308-4.884-5.33-8.566-10.31-9.44l-36.42-6.39
			c-7.159-1.255-12.388-7.462-12.41-14.73v-30.88c0.002-7.283,5.236-13.512,12.41-14.77l36.43-6.39c4.966-0.871,8.98-4.535,10.3-9.4
			c3.96-14.783,9.833-28.987,17.47-42.25c2.542-4.388,2.3-9.854-0.62-14l-21.22-30.24c-4.181-5.965-3.472-14.071,1.68-19.22
			l21.84-21.84c5.149-5.152,13.255-5.861,19.22-1.68L153.75,102c4.146,2.92,9.612,3.162,14,0.62
			c13.262-7.639,27.466-13.512,42.25-17.47c4.884-1.309,8.566-5.33,9.44-10.31l6.39-36.43c1.255-7.159,7.462-12.388,14.73-12.41
			h30.88c7.287-0.003,13.522,5.232,14.78,12.41l6.39,36.43c0.866,4.967,4.526,8.985,9.39,10.31c14.78,3.961,28.98,9.834,42.24,17.47
			c4.388,2.542,9.854,2.3,14-0.62l30.25-21.22c5.965-4.181,14.071-3.472,19.22,1.68l21.84,21.84
			c5.152,5.149,5.861,13.255,1.68,19.22L410,153.76c-2.92,4.146-3.162,9.612-0.62,14c7.635,13.26,13.508,27.46,17.47,42.24
			c1.312,4.88,5.333,8.558,10.31,9.43l36.43,6.39c7.163,1.256,12.393,7.468,12.41,14.74v30.88c0.003,7.287-5.232,13.522-12.41,14.78
			c-7.072,1.243-11.798,7.983-10.555,15.055c1.243,7.072,7.983,11.798,15.055,10.555c19.624-3.421,33.938-20.47,33.91-40.39v-30.88
			C512.003,220.646,497.695,203.609,478.08,200.17z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M475.07,391.53l-83.16-85.69c-10.874-11.145-16.322-26.493-14.91-42c5.968-68.273-44.539-128.457-112.812-134.425
			c-7.249-0.634-14.54-0.629-21.788,0.015c-5.68,0.462-11.017,2.904-15.08,6.9c-9.885,9.722-10.037,25.61-0.34,35.52l35.83,36.53
			c7.282,7.417,7.173,19.332-0.244,26.614c-0.002,0.002-0.004,0.004-0.006,0.006l-27.74,27.21c-7.421,7.275-19.333,7.158-26.61-0.26
			l-35.83-36.53c-9.722-9.885-25.61-10.037-35.52-0.34c-4.079,3.985-6.628,9.276-7.2,14.95
			c-7.372,68.135,41.887,129.346,110.022,136.718c7.239,0.783,14.533,0.928,21.798,0.432c15.498-1.112,30.707,4.609,41.63,15.66
			l83.11,85.63c5.001,5.153,13.232,5.276,18.385,0.275c5.153-5.001,5.276-13.232,0.275-18.385l-83.16-85.68
			c-16.246-16.503-38.899-25.071-62-23.45c-54.047,3.704-100.863-37.107-104.567-91.154c-0.341-4.978-0.302-9.974,0.117-14.946
			l34.36,35c8.307,8.514,19.675,13.35,31.57,13.43h0.44c11.73,0.036,23-4.561,31.36-12.79l27.74-27.21
			c17.67-17.334,17.943-45.709,0.61-63.38l-34.36-35c54.058-3.531,100.744,37.429,104.276,91.487
			c0.325,4.981,0.27,9.98-0.165,14.953c-2.071,23.038,6.04,45.83,22.2,62.38l83.11,85.64c5.001,5.153,13.232,5.276,18.385,0.275
			C479.948,404.914,480.071,396.683,475.07,391.53z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default ConfigurationIcon;
