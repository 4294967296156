import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "../auth.module.css";

import CheckoutForm from "../StripeElements/CheckoutForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
// import CheckoutForm from "../CheckoutForm";
import { toast } from "react-toastify";

const stripePromise = loadStripe(
  "pk_live_51OyJO100LEznN676XZjpgtueCTNj73mEV6bHzhND5lYUCe1Xoteq2YaWVOdzMIggbOXxcvIBI34l6HsMAqRr05o200hbXxGsUd"
);

const PaymentInformation = ({ onNext, onBack, formData }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const customerId = formData.stripe_id;
    const priceId = formData?.subscription_price;

    fetch(
      "https://brandlink-24432.uc.r.appspot.com/payment/create-subscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          priceId: priceId,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Failed to create subscription.");
      });
  }, [formData]);

  const appearance = { theme: "stripe" };
  const options = clientSecret ? { clientSecret, appearance } : { appearance };

  const [error, setError] = useState(null);

  const canContinue = true;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.sidebar_payment}>
        {/* <img
          alt="logo"
          src="/BrandLinkLogoBlue.png"
          className={styles.logo_icon}
        ></img> */}
        <p className={styles.page_title}>Payment Details</p>
        <p className={styles.page_subtitle}>Enter Payment Information</p>

        {!clientSecret ? (
          <div className={styles.loader_layout}>
            <CircularLoader color="rgba(0, 88, 220, 1)" size="10%" />
          </div>
        ) : (
          <div className={styles.checkout_form}>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                clientSecret={clientSecret}
                formData={formData}
                onNext={onNext}
              />
            </Elements>
          </div>
        )}

        <p className={styles.previous_step} onClick={onBack}>
          Previous Step
        </p>
      </div>
    </>
  );
};

export default PaymentInformation;
