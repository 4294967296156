import React, { useState, useContext, useEffect } from "react";
import styles from "./popups.module.css";
import Checkbox from "../../PortalComponents/UniversalComponents/Checkbox/Checkbox";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import CancelIcon from "../../../assets/SVG/CancelIcon";
import { AuthContext } from "../../../context/AuthContext";
import { EmailContext } from "../../../context/EmailContext";
import { toast } from "react-toastify";

import AiButton from "../../PortalComponents/UniversalComponents/AiButton/AiButton";

const CreateEmailPopup = ({
  isOpen,
  onClose,
  activeEmail,
  connectedInboxes,
  contact,
  emailAddress,
}) => {
  const modalClass = isOpen
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const { profile } = useContext(AuthContext);
  const { createEmail } = useContext(EmailContext);

  const [to, setTo] = useState(emailAddress);
  const [isEmailValid, setIsEmailValid] = useState(emailAddress ? true : false);
  const [from, setFrom] = useState({});

  const extractFirstName = (fullName) => {
    return fullName.split(" ")[0];
  };

  const [subject, setSubject] = useState(
    contact?.full_name
      ? `${extractFirstName(contact.full_name)} x ${profile.companyName}`
      : ""
  );
  const [body, setBody] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeEmail) {
      setFrom(activeEmail);
    }
  }, [activeEmail]);

  const canSend = to && subject && from && body && isEmailValid;

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleBlurOrEnter = () => {
    if (validateEmail(to)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
      alert("Please enter a valid email address.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlurOrEnter();
    }
  };

  const handleDropdownClick = (account) => {
    setFrom(account);
    setIsDropdownOpen(false);
  };

  async function handleSendMessage() {
    console.log(body);
    setIsLoading(true);
    if (body.trim() !== "") {
      try {
        const to_info = [
          {
            display_name: to,
            identifier: to,
          },
        ];
        const subjectLine = subject;
        const emailBody = body;
        const cc = [];

        await createEmail(
          from.id,
          to_info,
          subjectLine,
          formatEmailBody(emailBody),
          cc,
          contact
        );
        onClose();
      } catch (error) {
        console.error("Error sending email:", error);
        onClose();
      } finally {
        setIsLoading(false);
        toast.success("Email Sent");
      }
    }
  }

  function formatEmailBody(text) {
    return text.replace(/\n/g, "<br>");
  }

  // const testFunction = async () => {
  //   setIsLoading(true);
  //   try {
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     console.log("Operation completed");
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setIsLoading(false);
  //     toast.success("Email Sent");
  //   }
  // };

  return (
    <div className={modalClass} onClick={onClose}>
      <div className={styles.modal_body} onClick={stopPropagation}>
        <div className={styles.close_icon} onClick={onClose}>
          <CancelIcon />
        </div>
        <div className={styles.modal_content}>
          <p className={styles.header_title}>New Email</p>
          <div className={styles.email_info}>
            <p className={styles.email_info_title}>To:</p>
            {isEmailValid ? (
              <div
                className={styles.email_display}
                onClick={() => setIsEmailValid(false)}
              >
                {contact?.image_url && (
                  <img
                    className={styles.contact_image}
                    src={contact.image_url}
                  />
                )}
                {to}
              </div>
            ) : (
              <input
                placeholder="Search or add a contact"
                className={styles.email_info_content}
                value={to}
                onChange={(e) => setTo(e.target.value)}
                onBlur={handleBlurOrEnter}
                onKeyPress={handleKeyPress}
              />
            )}
          </div>

          <div className={styles.section_line_divider}></div>
          <div className={styles.email_info}>
            <p className={styles.email_info_title}>From:</p>
            <div
              className={styles.email_from}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {from?.name}
              <img
                src="/images/general_icons/DownArrowIcon.png"
                alt="down"
                className={`${styles.down_icon} ${
                  isDropdownOpen ? styles.rotate_180 : ""
                }`}
              ></img>
              {isDropdownOpen && (
                <div className={styles.dropdown}>
                  {connectedInboxes.map((account, index) => (
                    <div
                      key={index}
                      className={styles.dropdown_item}
                      onClick={() => handleDropdownClick(account)}
                    >
                      {account.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={styles.section_line_divider}></div>
          <div className={styles.email_info}>
            <p className={styles.email_info_title}>Subject:</p>
            <input
              placeholder="Add a subject"
              className={styles.email_info_content}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={styles.section_line_divider}></div>
          <div className={styles.email_body}>
            <textarea
              className={styles.email_body_area}
              placeholder="Write your message here..."
              value={body}
              onChange={(e) => setBody(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className={styles.modal_bottom}>
          <div className={styles.ai_button}>
            <AiButton setReply={setBody} contact={contact} />
          </div>
          <div
            className={
              canSend
                ? styles.link_button
                : `${styles.v} ${styles.link_button_disabled}`
            }
            onClick={canSend ? () => handleSendMessage() : null}
          >
            {isLoading ? (
              <CircularLoader size="30%" color="rgba(0, 88, 220, 1)" />
            ) : (
              "Send"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEmailPopup;
