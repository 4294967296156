// import "./chat.css";
import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import styles from "./inbox.module.css";
import InboxSideProfile from "./inboxSideProfile";
import InboxMessageInput from "./inboxMessageInput";
// import { ChatContext } from "../../../context/InboxContext";
import { AuthContext } from "../../../context/AuthContext";
import { EmailContext } from "../../../context/EmailContext";
import ForwardIcon from "../../../assets/SVG/Forward";
import AddIcon from "../../../assets/SVG/AddIcon";
import CreateEmailPopup from "../../PopUpComponents/CreateEmailPopup/CreateEmailPopup";

import Reply from "../../../assets/SVG/Reply";
import ReplyAll from "../../../assets/SVG/ReplyAll";
import UserIcon from "../../../assets/SVG/UserIcon copy";
import WarningTriangle from "../../../assets/SVG/WarningTriangle";

import { useNavigate } from "react-router-dom";

import getEmailProviderImage from "../../../utils/getEmailProviderImage";

import prepareMessage, {
  blockRemoteContent,
  linkify,
} from "tempo-email-parser";

export default function InboxComponent() {
  const [isEditing, setIsEditing] = useState(false);
  const [loadingTrail, setLoadingTrail] = useState(false);
  const [replyingEmail, setReplyingEmail] = useState(false);

  const navigate = useNavigate();

  const [creatingEmail, setCreatingEmail] = useState(false);

  const [inboxItems] = useState(["Inbox", "Outbox"]);
  const [currentInboxType, setCurrentInboxType] = useState(inboxItems[0]);

  function openPopup() {
    setCreatingEmail(true);
  }

  function closePopup() {
    setCreatingEmail(false);
  }

  function handleConnectEmail() {
    navigate(`/settings/mailboxes`);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const { profile, setSidebarNotifications } = useContext(AuthContext);

  const {
    emails,
    setEmails,
    selectedTrail,
    setSelectedTrail,
    // fetchEmails,
    fetchMessages,
    fetchEmailsByAccount,
    markThreadAsRead,
  } = useContext(EmailContext);

  const textAreaRef = useRef(null);
  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const [connectedInboxes, setConnectedInboxes] = useState(null);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (profile && profile.connected_accounts) {
      setConnectedInboxes(profile.connected_accounts);
      setActiveTab(profile.connected_accounts[0]);
    }
  }, [profile]);

  useEffect(() => {
    const fetchEmailData = async () => {
      if (activeTab && currentInboxType) {
        // Ensure currentInboxType is also considered
        setSelectedTrail(null);
        setLoadingTrail(true);
        try {
          // let data;
          // if (activeTab.type === "email") {
          //   data = await fetchEmailsByAccount(activeTab.id, currentInboxType);
          // } else {
          //   data = await fetchMessages(activeTab.id);
          // }
          const data = await fetchEmailsByAccount(
            activeTab.id,
            currentInboxType
          );
          console.log(data);
          setEmails(data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingTrail(false);
        }
      }
    };

    fetchEmailData();
  }, [activeTab, currentInboxType]);

  const selectChat = (chat) => {
    let wasUnread = false;

    const updatedEmails = emails.map((email) => {
      if (email.id === chat.id) {
        if (!email.threadInfo.read) {
          wasUnread = true;
        }
        const updatedThreadInfo = {
          ...email.threadInfo,
          read: true,
        };

        return {
          ...email,
          threadInfo: updatedThreadInfo,
        };
      }
      return email;
    });

    setEmails(updatedEmails);
    setSelectedTrail(chat);

    if (wasUnread) {
      setSidebarNotifications((prevNotifications) => ({
        ...prevNotifications,
        inbox: prevNotifications.inbox - 1,
      }));
      markThreadAsRead(chat.id);
    }
  };

  const replyToEmail = (email) => {
    setReplyingEmail(true);
  };

  function formatEmailPreviewText(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    let textContent = doc.body.textContent || "";
    textContent = textContent.replace(/([.,!?;:])([^ \n])/g, "$1 $2");
    return textContent;
  }

  const takeBeforeDivider = (fullName) => {
    if (fullName) {
      let nameBeforeDivider = fullName.split("|")[0];
      return nameBeforeDivider
        .toLowerCase() // Convert to lowercase to reset existing capitalization
        .split(" ") // Split into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(" "); // Join the words back into a single string
    } else {
      return "";
    }
  };

  function Channels() {
    return (
      <>
        {Array.isArray(emails) &&
          emails?.map((trail) => {
            const mostRecentEmail = trail.emails[trail.emails.length - 1];

            return (
              <React.Fragment key={trail.id}>
                <div className={styles.chat_card_box}>
                  {!trail.threadInfo.read && (
                    <div className={styles.unread_indicator}></div>
                  )}
                  <div
                    onClick={() => selectChat(trail)}
                    className={`${styles.chat_card} ${
                      selectedTrail?.id === trail.id
                        ? styles.selected_chat
                        : styles.non_selected_chat
                    }`}
                  >
                    <div className={styles.trail_channel_content}>
                      <div className={styles.trail_from}>
                        <div className={styles.user_image_bg}>
                          {trail.threadInfo?.contact_image ? (
                            <img
                              className={styles.user_image}
                              src={trail.threadInfo?.contact_image}
                              alt="Contact"
                            ></img>
                          ) : (
                            <UserIcon color="rgba(0, 88, 220)" />
                          )}
                        </div>
                        {takeBeforeDivider(
                          trail.threadInfo?.contact_display_name
                        ) || mostRecentEmail.from_attendee?.display_name}
                      </div>
                      <p className={styles.trail_subject}>
                        {mostRecentEmail.subject}
                      </p>
                      <p className={styles.trail_preview}>
                        {formatEmailPreviewText(
                          normalizeEmailContent(mostRecentEmail.body)
                        )}
                      </p>
                    </div>
                  </div>
                  <p className={styles.channel_timestamp}>
                    {formatTimestamp(mostRecentEmail.date)}
                  </p>
                </div>
              </React.Fragment>
            );
          })}
      </>
    );
  }

  const MessageComponent = ({ selectedChat }) => {
    const bodyRef = useRef(null);

    console.log(selectedChat.body);

    useEffect(() => {
      const fetchMail = async () => {
        const content = { body: selectedChat.body };

        if (bodyRef.current) {
          const shadowRoot = bodyRef.current.attachShadow({ mode: "open" });
          const mailBody = document.createElement("div");
          mailBody.id = "mail-body";
          mailBody.style.display = "table";
          mailBody.style.width = "100%";
          mailBody.style.position = "relative";
          mailBody.style.color = "black";
          mailBody.style.fontSize = "16px";
          mailBody.style.fontWeight = "300";
          mailBody.style.textAlign = "left";
          mailBody.style.margin = "0px";
          const normalizedContent = normalizeEmailContent(content.body);
          mailBody.innerHTML = normalizedContent;

          shadowRoot.appendChild(mailBody);
        }
      };

      fetchMail();
    }, [selectedChat]);

    return (
      <div className={styles.message_wrapper}>
        <div className={styles.message_background}>
          <div className={styles.message_header}>
            <div className={styles.account_info}>
              <div className={styles.account_image}>
                <UserIcon color="rgba(0, 88, 220)" />
              </div>
              <div className={styles.account_text}>
                <p className={styles.account_name}>
                  {selectedChat.from_attendee.display_name}
                </p>
                <p className={styles.account_email}>
                  {selectedChat.from_attendee.identifier}
                </p>
              </div>
            </div>
            <div className={styles.actions}>
              <div
                className={styles.action_button}
                onClick={() => replyToEmail(selectedChat)}
              >
                <Reply />
                <p className={styles.action_text}>Reply</p>
              </div>
              {/* <div
                className={styles.action_button}
                onClick={() => replyToEmail(selectedChat)}
              >
                <ReplyAll />
                <p className={styles.action_text}>Reply All</p>
              </div> */}
              {/* <div className={styles.action_button}>
                <ForwardIcon />
                <p className={styles.action_text}>Forward</p>
              </div> */}
            </div>
          </div>
          <div className={styles.message_divider}></div>
          <div ref={bodyRef} />

          {/* <div className={styles.message_divider}></div> */}
          <p className={styles.time_stamp_email}>
            {formatIsoDateToDate(selectedChat.date)}
          </p>
        </div>
      </div>
    );
  };

  function normalizeEmailContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Function to remove elements with specific classes and the div after divRplyFwdMsg
    function removeSpecificElements() {
      const divRplyFwdMsg = doc.querySelector("#divRplyFwdMsg");
      if (divRplyFwdMsg) {
        let nextDiv = divRplyFwdMsg.nextElementSibling;
        if (nextDiv && nextDiv.tagName === "DIV" && !nextDiv.id) {
          nextDiv.remove();
        }
      }

      doc.querySelectorAll(".gmail_attr, .gmail_quote").forEach((el) => {
        el.parentNode.removeChild(el);
      });

      doc
        .querySelectorAll(
          "script, style, link, #divRplyFwdMsg, #appendonsend, hr"
        )
        .forEach((el) => {
          el.remove();
        });
    }

    // Function to remove all styles and classes
    function removeAttributes() {
      doc.querySelectorAll("*").forEach((el) => {
        el.removeAttribute("class");
        el.removeAttribute("style");
      });
    }

    // Function to remove quoted text
    function removeQuotedText(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent;
        const index = textContent.indexOf(">");
        if (index !== -1) {
          node.textContent = textContent.substring(0, index);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(node.childNodes);
        childNodes.forEach(removeQuotedText);
      }
    }

    // Function to remove two preceding <br> tags before elements with class "gmail_quote"
    function removePrecedingBrTags() {
      const quotes = doc.querySelectorAll(".gmail_quote");
      quotes.forEach((quote) => {
        let prevSibling = quote.previousSibling;
        let brCount = 0;
        while (prevSibling && brCount < 2) {
          // Check if the previous sibling is a <br> tag
          if (
            prevSibling.nodeType === Node.ELEMENT_NODE &&
            prevSibling.tagName === "BR"
          ) {
            let toRemove = prevSibling;
            prevSibling = prevSibling.previousSibling;
            toRemove.remove();
            brCount++;
          } else if (
            prevSibling.nodeType === Node.TEXT_NODE &&
            !prevSibling.textContent.trim()
          ) {
            // If it's an empty text node, just move to the previous sibling
            prevSibling = prevSibling.previousSibling;
          } else {
            break;
          }
        }
      });
    }

    // Call the functions to clean up the HTML
    removeSpecificElements();
    removeAttributes();
    removeQuotedText(doc.body);
    removePrecedingBrTags();

    return doc.body.innerHTML;
  }

  const [inboxDropdown, setInboxDropdown] = useState(false);
  const [campaignDropdown, setCampaignDropdown] = useState(false);

  const Dropdown = ({
    title,
    items,
    isOpen,
    toggleDropdown,
    setterFunction,
  }) => {
    return (
      <div className={styles.header_dropdown} onClick={toggleDropdown}>
        <p className={styles.dropdown_title}>{title}</p>
        <img
          src="/images/general_icons/DownArrowIcon.png"
          alt="down"
          className={`${styles.down_icon} ${isOpen ? styles.rotate_180 : ""}`}
        />
        {isOpen && (
          <div className={styles.dropdown_content}>
            {items.map((item, index) => (
              <div
                className={styles.dropdown_item}
                onClick={() => setterFunction(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(scrollToBottom, [selectedTrail]);

  return (
    <div className={styles.main_layout}>
      <CreateEmailPopup
        isOpen={creatingEmail}
        onClose={closePopup}
        activeEmail={activeTab}
        connectedInboxes={connectedInboxes}

        // isLoading={isLoading}
      />
      <div className={styles.inbox_header}>
        <div className={styles.inbox_header_content}>
          <div className={styles.header_content}>
            <p className={styles.page_title}>Your Inboxes</p>
            <p className={styles.header_content_subtitle}>
              Connect and monitor all your incoming messages from your global
              inbox
            </p>
          </div>
          <div className={styles.header_controls}>
            <Dropdown
              title={currentInboxType}
              items={inboxItems}
              isOpen={inboxDropdown}
              toggleDropdown={() => setInboxDropdown(!inboxDropdown)}
              setterFunction={setCurrentInboxType}
            />
            <Dropdown
              title="Campaigns"
              items={inboxItems}
              // Assuming campaigns dropdown is always visible for now,
              // or implement a similar state management as for the Inbox
              isOpen={campaignDropdown}
              toggleDropdown={() => setCampaignDropdown(!campaignDropdown)}
            />
            {/* <div className={styles.header_dropdown}>
              <p className={styles.dropdown_title}>Tags</p>
              <img
                src="/images/general_icons/DownArrowIcon.png"
                alt="down"
                className={styles.down_icon}
              ></img>
            </div> */}
            <p className={styles.clear_filters}>Clear Filters</p>
          </div>
        </div>
        {/* <p className={styles.last_updated}>Last Updated 4:35pm</p> */}
      </div>
      <div className={styles.inbox_content}>
        <div className={styles.inboxes_sidebar}>
          {/* <p className={styles.mailbox_title}>Your Mailboxes</p> */}
          <div className={styles.inboxes_sidebar_content}>
            {connectedInboxes?.length && connectedInboxes?.length !== 0 ? (
              <>
                {connectedInboxes?.map((tab) =>
                  tab.disconnected ? (
                    <div
                      key={tab}
                      className={`${styles.tab} ${styles.tab_disconnected} ${
                        activeTab === tab ? styles.active_disconnected : ""
                      }`}
                      onClick={() => handleConnectEmail()}
                    >
                      {/* <img
                        className={styles.inbox_icon}
                        src={getEmailProviderImage(tab.type)}
                      ></img> */}
                      <div className={styles.inbox_icon}>
                        <WarningTriangle />
                      </div>

                      {tab.name}
                    </div>
                  ) : (
                    <div
                      key={tab}
                      className={`${styles.tab} ${
                        activeTab === tab ? styles.active : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      <img
                        className={styles.inbox_icon}
                        src={getEmailProviderImage(tab.type)}
                      ></img>
                      {tab.name}
                    </div>
                  )
                )}
              </>
            ) : (
              <div className={styles.no_mailboxes_connected}>
                <div
                  className={styles.connect_mailbox_button}
                  onClick={handleConnectEmail}
                >
                  Connect First Mailbox
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.sidebar}>
          {/* SEARCH BUTTON */}
          <div className={styles.search_container}>
            <input
              className={styles.search_input}
              value={searchQuery}
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
            />
            <div className={styles.create_email} onClick={openPopup}>
              <AddIcon color="rgba(0, 0, 0, 0.65)" />
            </div>
          </div>

          <div className={styles.loading_line_background}>
            {loadingTrail && <div className={styles.loading_line}></div>}
          </div>

          {emails?.length && emails?.length !== 0 ? (
            <div className={styles.chat_list}>
              <Channels />
            </div>
          ) : (
            <div className={styles.empty}>
              <img className={styles.empty_image} src="/emptyInbox.png"></img>
              <p className={styles.empty_text}>No Threads</p>
            </div>
          )}
        </div>

        {selectedTrail ? (
          <>
            <div className={styles.chat_container}>
              <div className={styles.chat_layout_content_info}>
                <div className={styles.chat_header}>
                  <p className={styles.subjectLine}>
                    Subject: {selectedTrail.emails[0].subject}
                  </p>
                </div>
                <div className={styles.chat_messages}>
                  {selectedTrail.emails.map((trail) => (
                    <MessageComponent key={trail.id} selectedChat={trail} />
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              </div>
              <div className={styles.chat_input}>
                <InboxMessageInput selectedChat={selectedTrail} />
                {/* <div className={styles.editor_background}>
                    <EmailTextEditor />
                  </div> */}
              </div>
              {/* {replyingEmail && (
                  <div className={styles.chat_input}>
                    <InboxMessageInput selectedChat={selectedTrail} />
                  </div>
                )} */}
            </div>

            {/* <InboxSideProfile selectedChat={selectedTrail} /> */}
          </>
        ) : (
          <div className={styles.no_chat_selected}>
            <div className={styles.select_chat_box}>
              <p className={styles.select_chat_text}>Select a Thread</p>
              <img
                className={styles.select_chat_image}
                src="/images/general_icons/NoSelectedChatIcon.svg"
                alt="no chat"
              ></img>
            </div>
          </div>
        )}
        {/* {selectedChat ? (
        <InboxSideProfile selectedChat={selectedChat} />
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img className={styles.select_chat_image}></img>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
}

function formatTimestampTo12Hour(timestamp) {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutesStr + " " + ampm;
}

function formatIsoDateToDate(isoDateString) {
  try {
    const date = new Date(isoDateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error in formatting ISO date string:", error);
    return "";
  }
}

function formatTimestamp(timestamp) {
  try {
    const date = new Date(timestamp);
    const now = new Date();

    // Check if the date is the same as today
    const isSameDay =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    if (isSameDay) {
      // Format time as hh:mm AM/PM
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return date.toLocaleTimeString(undefined, options);
    } else {
      // Format date as abbreviated date: MMM d
      const options = {
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    }
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}
