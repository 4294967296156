import React from "react";
import styles from "./popups.module.css";
import CreateCampaignComponents from "../../PortalComponents/CreateCampaignComponents/CreateCampaignComponents";

const UpgradePlan = ({ isOpen, onClose }) => {
  const modalClass = isOpen
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={modalClass} onClick={onClose}>
      <div className={styles.modal_body} onClick={stopPropagation}>
        <div className={styles.modal_header}>
          <p className={styles.header_title}>
            Expand Your Search by Upgrading to Premium
          </p>
        </div>
        <img
          className={styles.modal_image}
          src="/premium.png"
          alt="premium"
        ></img>
        {/* <h2>Upgrade Your Plan</h2>
        <p>This is your modal content. You can add more content here.</p>
        <button onClick={onClose}>Close</button> */}
        <div className={styles.modal_button}>Upgrade Subscription</div>
      </div>
    </div>
  );
};

export default UpgradePlan;
