import React, { useContext, useState } from "react";
import styles from "./ai_button.module.css";
import BackIcon from "../../../../assets/SVG/BackIcon";
import { AuthContext } from "../../../../context/AuthContext";
import CircularLoader from "../CircularLoader/CircularLoader";

function AiButton({ setReply, contact }) {
  const { profile, generateAiReply } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const extractFirstName = (fullName) => {
    if (fullName) {
      return fullName.split(" ")[0];
    } else {
      return "";
    }
  };

  const companyName = "BrandLink";

  const companyInfo =
    "BrandLink is a startup build to discover and automate creator outreach. You can run advanced searches and run outerach campagins on filtered contacts";
  const companyWebsite = "https://www.brandlink.ai/";

  const aiSystemPrompt = `You are an Ai trained to write short, friendly, concise, good, emails. Don't sound too flattering or fake and dont't use the word impressive or compliment them too much. Keep the email about why they would be a good canidate to work with. Keep it brief though and avoid copying too much from their bio. You are writing an email to ${extractFirstName(
    contact?.full_name
  )}, their bio says  ${
    contact?.introduction
  }. Keep it short and straightforward, and don't sound fake. Write a short, consice email asking if they're interested in a partnership with ${companyName} in a friendly way. ${companyInfo}. The company website is ${companyWebsite}. Keep the email straightforward and concise. Use exclamation appoints on occasion, but not all the time. Be friendly, and not too rigid or robotic. No need to include the subject. The email is from ${
    profile?.firstName
  }. Keep you email short and avoid copying too much from their bio. Dont seem to impressed or fake, but moreso interested in partnering. Before writing a response take everything above and put it in your words.`;

  const typeReply = (reply, index = 0) => {
    if (index < reply.length) {
      setReply((currentText) => currentText + reply[index]);
      setTimeout(() => typeReply(reply, index + 1), 10); // Adjust timing as needed
    }
  };

  const generateReply = async (temperature = 1) => {
    setShowOptions(false);
    setIsLoading(true);
    setReply(""); // Clear previous reply if any
    try {
      const reply = await generateAiReply(aiSystemPrompt, temperature);
      console.log("AI reply generated successfully.");
      typeReply(reply); // Start the typing effect
    } catch (error) {
      console.error("Error generating AI reply:", error.message);
    }
    setIsLoading(false);
  };

  const toggleOptions = () => setShowOptions(!showOptions);

  return (
    <div
      className={styles.AI_button}
      onClick={() => toggleOptions()}
      onMouseEnter={() => setShowOptions(true)}
      //   onMouseLeave={() => setShowOptions(false)}
    >
      {isLoading ? (
        // <CircularLoader size="50%" color="rgba(255, 255, 255, 1)" />
        <div className={styles.loading_message}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <>
          Write with AI
          <div style={{ transform: "rotate(90deg)" }}>
            <BackIcon color="rgba(255, 255, 255, 1)" width={16} height={16} />
          </div>
        </>
      )}

      {showOptions && (
        <div
          className={styles.ai_options}
          onMouseLeave={() => setShowOptions(false)}
        >
          <div
            className={styles.ai_option_item}
            onClick={() => generateReply(0.5)}
          >
            Start a Conversation
          </div>
          <div
            className={styles.ai_option_item}
            onClick={() => generateReply(0.5)}
          >
            Suggest Partnership
          </div>
          <div
            className={styles.ai_option_item}
            onClick={() => generateReply(0.5)}
          >
            Schedule Meeting
          </div>
        </div>
      )}
    </div>
  );
}

export default AiButton;
