import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ContactsComponent from "../../components/PortalComponents/ContactsComponents/ContactsComponent";

const ContactsPage = () => {
  return (
    <>
      <Sidebar childComponent={<ContactsComponent />} activeText="Saved" />
    </>
  );
};

export default ContactsPage;
