import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./general_components.module.css";

export default function BoxedInfoList(props) {
  const data = props.data;

  const getPrefixedName = (name) => {
    switch (props.type) {
      case "hashtag":
        return "#" + name;
      case "mention":
        return "@" + name;
      default:
        return name;
    }
  };

  return (
    <div className={styles.boxed_info_layout}>
      <p className={styles.section_title}>{props.title}</p>
      <p className={styles.section_subtitle}>{props.subtitle}</p>
      <div className={styles.boxed_info}>
        {data.map((item, index) => (
          <div key={index} className={styles.item_box}>
            <p className={styles.item_info}>{getPrefixedName(item.name)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
