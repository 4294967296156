import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import FeedbackComponent from "../../components/PortalComponents/FeedbackComponents/FeedbackComponent";

const FeedbackPage = () => {
  return (
    <>
      <Sidebar childComponent={<FeedbackComponent />} activeText="Feedback" />
    </>
  );
};

export default FeedbackPage;
