import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styles from "./slider.module.css";

function SliderSelect({ frequency, frequency_options, handleFrequencyChange }) {
  return (
    <div className={styles.frequency_options}>
      <div className={styles.radio_inputs}>
        {frequency_options.map((option) => (
          <label className={styles.radio} key={option.key}>
            <input
              type="radio"
              name="frequency"
              value={option.key}
              onChange={handleFrequencyChange}
              checked={frequency === option.key}
            />
            <span className={styles.name}>{option.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default SliderSelect;
