import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreateCampaignComponents from "../../components/PortalComponents/CreateCampaignComponents/CreateCampaignComponents";

const CreateCampaignPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<CreateCampaignComponents />}
        activeText="Campaigns"
      />
    </>
  );
};

export default CreateCampaignPage;
