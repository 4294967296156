import React from "react";

const TargetingIcon = ({
  width = 17,
  height = 17,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 4.1,
  ...props
}) => (
  <svg
    id="fi_2910816"
    enable-background="new 0 0 24 24"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m10 24c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-19c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z"></path>
    </g>
    <g>
      <path d="m10 20.5c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5 5.5-2.5 5.5-5.5-2.5-5.5-5.5-5.5z"></path>
    </g>
    <g>
      <path d="m10 17c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </g>
    <g>
      <path d="m10 14.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.5-.2.7 0s.2.5 0 .7l-7.5 7.5c0 .1-.2.1-.3.1z"></path>
    </g>
    <g>
      <path d="m20 7h-2.5c-.3 0-.5-.2-.5-.5v-2.5c0-.1.1-.3.1-.4l3.5-3.5c.2-.1.4-.1.6-.1.2.1.3.3.3.5v2h2c.2 0 .4.1.5.3 0 .2 0 .4-.1.6l-3.5 3.5c-.1 0-.3.1-.4.1zm-2-1h1.8l2.5-2.5h-1.3c-.3 0-.5-.2-.5-.5v-1.3l-2.5 2.5z"></path>
    </g>
  </svg>
);

export default TargetingIcon;
