import React from "react";

const ContactPersonIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    id="fi_7980592"
    enable-background="new 0 0 32 32"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m24 3h-16c-2.757 0-5 2.243-5 5v16c0 2.757 2.243 5 5 5h16c2.757 0 5-2.243 5-5v-16c0-2.757-2.243-5-5-5zm3 21c0 1.654-1.346 3-3 3h-16c-1.654 0-3-1.346-3-3v-16c0-1.654 1.346-3 3-3h16c1.654 0 3 1.346 3 3z"></path>
      <path d="m16.003 15.826c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path>
      <path d="m16 16.522c-3.859 0-7 3.14-7 7 0 .552.447 1 1 1h12c.553 0 1-.448 1-1 0-3.86-3.141-7-7-7zm-4.899 6c.465-2.279 2.484-4 4.899-4s4.435 1.721 4.899 4z"></path>
    </g>
  </svg>
);

export default ContactPersonIcon;
