import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./profile_sections.module.css";
import BoxedInfoList from "./GeneralComponents/BoxedInfoList";

export default function GrowthInterestSection(props) {
  const profile = props.profile.profile;

  return (
    <div className={styles.section_layout}>
      <div className={styles.sub_section_layout}>
        <div className={styles.interests_layout}>
          <BoxedInfoList
            data={profile.top_hashtags}
            type={"hashtag"}
            title={"Frequently used hashtags"}
            subtitle={
              "Hashtags frequently used by the creator in their content"
            }
          />
          <BoxedInfoList
            data={profile.top_interests}
            title={"Creator's interests"}
            subtitle={"Interests and topics that the creator posts about"}
          />
          <BoxedInfoList
            data={profile.top_mentions}
            title={"Frequently tagged accounts"}
            type={"mention"}
            subtitle={
              "Accounts frequently tagged by the creator in their content"
            }
          />
        </div>
      </div>
    </div>
  );
}
