import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./profile_sections.module.css";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import BulletChart from "./GeneralComponents/BulletChart";
import AccountList from "./GeneralComponents/AccountList";

export default function AudienceSection(props) {
  const profile = props.profile.profile;

  const demographic_table_options = ["Ethnicity", "Language"];

  const [activeDemographicTable, setActiveDemographicTable] =
    useState("Ethnicity"); // Default to the 'Ethnicity' tab

  const handleTabClick = (tabName) => {
    setActiveDemographicTable(tabName);
  };

  return (
    <div className={styles.section_layout}>
      <div className={styles.sub_section_chart_layout}>
        <div className={styles.half_screen_layout}>
          <p className={styles.sub_section_title}>Audience Countries</p>
          <p className={styles.sub_section_subtitle}>
            Creator audience by country demographic
          </p>
          <BulletChart data={profile.audience.countries} />
        </div>
        <div className={styles.half_screen_layout}>
          <p className={styles.sub_section_title}>Audience Cities</p>
          <p className={styles.sub_section_subtitle}>
            Creator audience by city demographic
          </p>
          <BulletChart data={profile.audience.cities} />
        </div>
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div className={styles.sub_section_chart_layout}>
        <div className={styles.half_screen_layout}>
          <p className={styles.chart_section_title}>Gender Distribution</p>
          <GenderDistribution profile={profile} />
        </div>
        <div className={styles.half_screen_layout}>
          <p className={styles.chart_section_title}>Age Distribution</p>
          <AgeDistribution profile={profile} />
        </div>
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div className={styles.sub_section_layout_full}>
        <p className={styles.sub_section_title}>Audience Demographics</p>
        <div className={styles.slider_menu}>
          {demographic_table_options.map((item, index) => (
            <div
              onClick={() => handleTabClick(item)}
              className={
                item === activeDemographicTable
                  ? styles.active_slider_menu_item
                  : styles.slider_menu_item
              }
            >
              {item}
            </div>
          ))}
        </div>
        {activeDemographicTable === "Ethnicity" && (
          <DemographicBarChart
            data={profile.audience.ethnicities}
            type="Ethnicity"
          />
        )}
        {activeDemographicTable === "Language" && (
          <DemographicBarChart
            data={profile.audience.languages}
            type="Language"
          />
        )}
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div className={styles.sub_section_chart_layout}>
        <div className={styles.half_screen_layout}>
          <p className={styles.sub_section_title}>Audience brand affinity</p>
          <p className={styles.sub_section_subtitle}>
            Brands followed by the audience
          </p>
          <BulletChart data={profile.audience.interests} />
        </div>
        <div className={styles.half_screen_layout}>
          <p className={styles.sub_section_title}>Audience interests</p>
          <p className={styles.sub_section_subtitle}>
            Interests and topics that the audience posts about
          </p>
          <BulletChart data={profile.audience.interests} />
        </div>
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div className={styles.sub_section_layout_full}>
        <p className={styles.sub_section_title}>Follower types</p>
        <p className={styles.sub_section_subtitle}>
          Breakdown of follower legitamcy and type
        </p>
        <FollowerTypeBreakdown
          follower_types={profile.audience.follower_types}
        />
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
      <div>
        <AccountList
          data={profile.audience.lookalikes}
          title="Creators with similar audience"
        />
      </div>
    </div>
  );
}

function GenderDistribution(props) {
  const GenderAgeDist = props.profile.audience.gender_age_distribution;

  // Calculate the total values for Male, Female, and Other
  const maleTotal = GenderAgeDist.reduce((total, data) => {
    if (data.gender === "MALE") {
      return total + data.value;
    }
    return total;
  }, 0);

  const femaleTotal = GenderAgeDist.reduce((total, data) => {
    if (data.gender === "FEMALE") {
      return total + data.value;
    }
    return total;
  }, 0);

  const otherTotal = GenderAgeDist.reduce((total, data) => {
    if (data.gender !== "MALE" && data.gender !== "FEMALE") {
      return total + data.value;
    }
    return total;
  }, 0);

  const data = {
    labels: ["Male", "Female", "Other"],
    datasets: [
      {
        data: [maleTotal, femaleTotal, otherTotal],
        backgroundColor: [
          "rgb(0, 128, 255)",
          "rgb(191, 0, 255)",
          "rgb(255, 220, 0)",
          "rgb(255, 20, 147)",
          "rgb(0, 255, 210)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "90%",
    layout: {
      padding: {
        right: 40,
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart) => {
            return chart.data.labels.map((label, index) => {
              return {
                text: `${chart.data.datasets[0].data[index].toFixed(
                  2
                )}% \n ${label}`,
                fillStyle: chart.data.datasets[0].backgroundColor[index],
                strokeStyle: "#fff",
                lineWidth: 2,
                pointStyle: "circle",
              };
            });
          },
        },
      },
    },
  };

  const chartStyle = {
    // width: "430px",
    height: "300px",
  };

  return (
    <div style={chartStyle}>
      <Doughnut data={data} options={options} />
    </div>
  );
}

function AgeDistribution(props) {
  const ageData = props.profile.audience.gender_age_distribution;

  // Aggregate the data by age range
  const ageRanges = [...new Set(ageData.map((item) => item.age_range))];
  const aggregatedData = ageRanges.map((ageRange) => ({
    age_range: ageRange,
    value: ageData
      .filter((item) => item.age_range === ageRange)
      .reduce((total, item) => total + item.value, 0),
  }));

  // Define an array of colors for age ranges
  const colors = [
    "rgb(0, 128, 255)",
    "rgb(191, 0, 255)",
    "rgb(255, 220, 0)",
    "rgb(255, 20, 147)",
    "rgb(0, 255, 210)",
  ];

  const data = {
    labels: aggregatedData.map((item) => item.age_range),
    datasets: [
      {
        data: aggregatedData.map((item) => item.value),
        backgroundColor: colors.slice(0, ageRanges.length), // Use the colors array for the dataset
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "90%",
    layout: {
      padding: {
        right: 40, // Adjust the right padding to move the legend to the right
      },
    },
    plugins: {
      legend: {
        position: "right", // Move legend to the right side
        labels: {
          usePointStyle: true, // Use circles instead of boxes
        },
      },
    },
  };

  const chartStyle = {
    // width: "430px",
    height: "300px",
  };

  return (
    <div style={chartStyle}>
      <Doughnut data={data} options={options} />
    </div>
  );
}

function DemographicBarChart(props) {
  const languageData = props.data;
  const type = props.type;

  const values = languageData.map((item) => item.value);
  let labels;
  if (type === "Ethnicity") {
    labels = languageData.map((item) => item.name);
  }
  if (type === "Language") {
    labels = languageData.map((item) => item.code);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Language Data",
        data: values,
        backgroundColor: "rgb(0, 88, 220)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const chartStyle = {
    width: "100%",
    height: "430px",
  };

  return (
    <div style={chartStyle}>
      <Bar data={data} options={options} />
    </div>
  );
}

function FollowerTypeBreakdown(props) {
  const followerData = props.follower_types;

  // Define an array of colors for follower types
  const colors = [
    "rgb(0, 128, 255)",
    "rgb(191, 0, 255)",
    "rgb(255, 220, 0)",
    "rgb(255, 20, 147)",
    "rgb(0, 255, 210)",
  ];

  const data = {
    labels: followerData.map((item) => item.name),
    datasets: [
      {
        data: followerData.map((item) => item.value),
        backgroundColor: colors.slice(0, followerData.length), // Use the colors array for the dataset
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "85%",
    layout: {
      padding: {
        right: 40, // Adjust the right padding to move the legend to the right
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const chartStyle = {
    width: "300px",
    height: "300px",
  };

  return (
    <div style={chartStyle}>
      <div className={styles.chart_layout}>
        <Doughnut data={data} options={options} />
        <div className={styles.legend_layout}>
          {followerData
            .sort((a, b) => b.value - a.value)
            .map((item, index) => (
              <div key={index} className={styles.legend_item}>
                <div className={styles.legend_item_top}>
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: colors[index],
                    }}
                  ></div>
                  <p className={styles.legend_value}>{item.value}%</p>
                </div>
                <p className={styles.legend_title}>{formatWord(item.name)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function formatWord(str) {
  return str
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of every word
    .join(" "); // Join the words with spaces
}
