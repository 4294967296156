import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import { toast } from "react-toastify";

const RequestAccessComponent = () => {
  const navigate = useNavigate();
  const { addWaitlistUser } = useContext(AuthContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  const [loading, setLoading] = useState("");

  const [error, setError] = useState(null);

  const [confirmed, setConfirmed] = useState(false);

  function handleSignUp() {
    navigate("/sign-up");
  }

  function handleForgotPassword() {
    navigate("/forgot-password");
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addWaitlistUser(email, name, company);
      toast.success("Done!");
      setConfirmed(true);
    } catch (error) {
      console.error("Waitlist error:", error);
    } finally {
      setLoading(false);
    }
  };

  const extractFirstName = (fullName) => {
    if (fullName) {
      return fullName.split(" ")[0];
    } else {
      return "";
    }
  };

  const navigateToBrandLink = () => {
    window.location.href = "https://www.brandlink.ai/";
  };

  const canContinue = email !== "" && name !== "";

  return (
    <div className={styles.home_components}>
      {!confirmed ? (
        <div className={styles.sidebar}>
          <img
            alt="logo"
            src="/BrandLinkLogoBlue.png"
            className={styles.logo_icon}
          ></img>
          <p className={styles.page_title}>Hey there 👋</p>
          <p className={styles.page_subtitle}>
            Request early access to BrandLink below
          </p>
          <p className={styles.login_input_titles}>Full Name</p>
          <div className={styles.email_input}>
            <input
              value={name}
              onChange={(event) => setName(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
            ></input>
          </div>

          <p className={styles.login_input_titles}>
            Company Website (optional)
          </p>
          <div className={styles.email_input}>
            <input
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <div className={styles.spacer}></div>
          {error && <p className={styles.error_text}>{error}</p>}
          {canContinue ? (
            <div onClick={handleSubmit} className={styles.page_button}>
              {loading ? (
                <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
              ) : (
                <p className={styles.join_button_text}>Request Access</p>
              )}
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Request Access</p>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.sidebar}>
          <img
            alt="logo"
            src="/BrandLinkLogoBlue.png"
            className={styles.logo_icon}
          ></img>
          <div className={styles.confirmed_box}>
            <img
              className={styles.rocket}
              src="/rocket.gif"
              alt="Description of the GIF"
            />
            <>
              <p className={styles.confirmed_title}>
                You're on the list {extractFirstName(name)}!
              </p>
              <p className={styles.confirmed_subtitle}>
                A team member will reach out to you shortly
              </p>
            </>
            <div className={styles.home_button} onClick={navigateToBrandLink}>
              Back to Home
            </div>
          </div>
        </div>
      )}

      <div className={styles.right_side_sign_in}>
        <img className={styles.blue_background} src="/abstractbg.jpeg"></img>
        {/* <img className={styles.preview_ss} src="/SignInSS.png"></img> */}
      </div>
    </div>
  );
};

export default RequestAccessComponent;
