import React from "react";

const PauseIcon = ({
  width = 10,
  height = 10,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    viewBox="-45 0 327 327"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1214679"
  >
    <path d="m158 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path>
    <path d="m8 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path>
  </svg>
);

export default PauseIcon;
