import React, { useState, useEffect } from "react";
import styles from "./stages.module.css";
import CheckboxWithLabel from "../../UniversalComponents/CheckboxWithLabel/CheckboxWithLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default function CampaignConfig({
  newCampaign,
  handleInputChange,
  setValidLastStage,
}) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    setValidLastStage(true);
  }, []);

  const handleDayChange = (day) => {
    const newDays = newCampaign.campaign_days.includes(day)
      ? newCampaign.campaign_days.filter((d) => d !== day)
      : [...newCampaign.campaign_days, day];

    handleInputChange("campaign_days", newDays);
  };

  return (
    <div className={styles.campaign_stage}>
      <p className={styles.account_targeting}>Campaign Configuration</p>
      <p className={styles.section_title}>Active Campaign Times</p>
      <div className={styles.active_days}>
        {days.map((day, index) => (
          <CheckboxWithLabel
            key={index}
            label={day}
            checked={newCampaign.campaign_days.includes(day)}
            onCheckboxChange={() => handleDayChange(day)}
          />
        ))}
      </div>
      {/* <p className={styles.section_title}>Active Campaign Hours</p> */}
      <div className={styles.active_times}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Day Start"
            value={newCampaign.campaign_runtime[0]}
          />
          <TimePicker label="Day End" value={newCampaign.campaign_runtime[1]} />
        </LocalizationProvider>
      </div>
    </div>
  );
}
