import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";

const GeneralInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const navigate = useNavigate();

  const { addAdditionalRegistrationInformation } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const [hearAboutUsDropdown, setHearAboutUsDropdown] = useState(false);
  const [industryDropdown, setIndustryDropdown] = useState(false);

  function handleSignIn() {
    navigate("/sign-in");
  }

  const canContinue =
    formData.reasonForUse && formData.industry && formData.hearAboutUs;

  async function handleAddRegistrationInformation() {
    setLoading(true);
    try {
      const register = await addAdditionalRegistrationInformation(formData);

      if (register.success) {
        onNext();
      } else {
        setError(register.message);
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError("An error occurred during registration.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.sidebar}>
      <img
        alt="logo"
        src="/BrandLinkLogoBlue.png"
        className={styles.logo_icon}
      ></img>
      <p className={styles.page_title}>Your Goals with BrandLink</p>
      <p className={styles.page_subtitle}>
        Share why you're here and what you hope to achieve
      </p>

      <p className={styles.login_input_titles}>
        Why do you want to use BrandLink?
      </p>
      <div className={styles.paragraph_input}>
        <textarea
          value={formData.reasonForUse}
          onChange={(event) =>
            handleInputChange("reasonForUse", event.target.value)
          }
          className={styles.textarea_input}
        ></textarea>
      </div>
      <p className={styles.login_input_titles}>
        What industry are you interested in?
      </p>
      <div
        className={styles.sign_up_input}
        onClick={() => {
          setIndustryDropdown(!industryDropdown);
          setHearAboutUsDropdown(false);
        }}
      >
        <input
          readonly
          value={formData.industry}
          onChange={(event) =>
            handleInputChange("industry", event.target.value)
          }
          className={styles.input}
        ></input>
        <img
          src="/images/general_icons/DownArrowIcon.png"
          alt="down"
          className={`${styles.down_icon} ${
            industryDropdown ? styles.rotate_180 : ""
          }`}
        />
        {industryDropdown && (
          <div className={styles.dropdown_selection}>
            {companyIndustries?.map((option, index) => (
              <div
                className={styles.dropdown_selection_options}
                onClick={() => handleInputChange("industry", option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
      <p className={styles.login_input_titles}>How did you hear about us?</p>
      <div
        className={styles.sign_up_input}
        onClick={() => {
          setHearAboutUsDropdown(!hearAboutUsDropdown);
          setIndustryDropdown(false);
        }}
      >
        <input
          readonly
          value={formData.hearAboutUs}
          onChange={(event) =>
            handleInputChange("hearAboutUs", event.target.value)
          }
          className={styles.input}
        ></input>
        <img
          src="/images/general_icons/DownArrowIcon.png"
          alt="down"
          className={`${styles.down_icon} ${
            hearAboutUsDropdown ? styles.rotate_180 : ""
          }`}
        />
        {hearAboutUsDropdown && (
          <div className={styles.dropdown_selection}>
            {howDidYouHearAboutUsOptions?.map((option, index) => (
              <div
                className={styles.dropdown_selection_options}
                onClick={() => handleInputChange("hearAboutUs", option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.spacer}></div>
      {error && <p className={styles.error_text}>{error}</p>}
      {canContinue ? (
        <div
          className={styles.page_button}
          onClick={handleAddRegistrationInformation}
        >
          {loading ? (
            <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
          ) : (
            <p className={styles.join_button_text}>Continue</p>
          )}
        </div>
      ) : (
        <div className={styles.page_button_inactive}>
          <p className={styles.join_button_text}>Continue</p>
        </div>
      )}
      <p className={styles.previous_step} onClick={onBack}>
        Previous Step
      </p>
    </div>
  );
};

export default GeneralInformation;

const howDidYouHearAboutUsOptions = [
  "Internet Search (Google, Bing, etc.)",
  "Social Media (Facebook, Instagram, Twitter, etc.)",
  "Recommendation from a friend or colleague",
  "Online Advertisement",
  "Email Newsletter",
  "Other",
  //   "YouTube",
  //   "TikTok",
  //   "LinkedIn",
];

const companyIndustries = [
  "Accounting",
  "Media",
  "Finance",
  "Community",
  "Construction",
  "Consulting",
  "Design",
  "Education",
  "Engineering",
  "Agriculture",
  "Healthcare",
  "Tourism",
  "HR",
  "Tech",
  "Insurance",
  "Legal",
  "Logistics",
  "Marketing",
  "Mining",
  "Music",
  "Real Estate",
  "Retail",
  "Sales",
  "Science",
  "Social Media",
  "Sports",
];
