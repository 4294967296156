import React, { useState } from "react";
import styles from "./stages.module.css";

export default function FinalizeCampaign({ newCampaign, handleInputChange }) {
  return (
    <div className={styles.campaign_stage}>
      <p className={styles.account_targeting}>Campaign Overview</p>
      <div className={styles.campaign_overview}>
        <p className={styles.campaign_overview_title}>
          Affiliate Recruiement Campaign
        </p>
        <p className={styles.campaign_overview_description}>
          This Campaign aims to expand our affiliate network by recruiting new
          affiliates who can promote our products or services.
        </p>
        <div className={styles.campaign_overview_divider}></div>
        <p className={styles.campaign_overview_section_title}>
          Campaign Outlook
        </p>
        <p className={styles.campaign_overview_section_text}>
          16 Contacts • 2 Scheduled Messages Per Sequence
        </p>
        <div className={styles.campaign_overview_divider}></div>
        <p className={styles.campaign_overview_section_title}>
          Active Campaign Times
        </p>
        <p className={styles.campaign_overview_section_text}>
          Active on: Monday, Tuesday, Wednesday
        </p>
        <div className={styles.spacer_sm}></div>
        <p className={styles.campaign_overview_section_text}>
          Active From: 8am to 5pm
        </p>
      </div>
    </div>
  );
}
