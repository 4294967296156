import styles from "./navbar.module.css";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import NotificationsSidebar from "../Notifications/Notifications";
import { AuthContext } from "../../context/AuthContext";

export default function Navbar(props) {
  const { logout, profile } = useContext(AuthContext);
  const menuRef = useRef();
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  async function handleLogout() {
    try {
      await logout();
      navigate("/sign-in");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  const firebaseLink =
    "https://console.firebase.google.com/u/0/project/brandlink-24432/overview";

  const googleCloudPlatformLink =
    "https://console.cloud.google.com/home/dashboard?hl=en&project=brandlink-24432";

  const loggingLink =
    "https://console.cloud.google.com/logs/query;cursorTimestamp=2023-12-10T20:22:52.410417032Z;duration=PT30M?hl=en&project=brandlink-24432";

  const handleLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const navigate = useNavigate();
  const navigateTo = (url) => {
    return navigate(url);
  };

  function switchToUserMode() {
    navigate("/dashboard");
  }

  function handleSettings() {
    navigate("/settings");
  }

  const [showMenu, setShowMenu] = useState(false);

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={styles.portal_navbar}>
      {/* <div className={styles.portal_navbar_sidebar_extension}></div>
      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          <div className={styles.container_button} ref={menuRef}>
            {profile && (
              <div className={styles.account_navbar_item} onClick={toggleMenu}>
                <img
                  src={profile.profilePicture || "/DefaultAccountImage.png"}
                  alt="icon"
                  className={styles.account_pfp}
                ></img>
                <div className={styles.account_info}>
                  <p className={styles.account_name}>
                    {profile.firstName} {profile.lastName}
                  </p>
                  {profile.company && (
                    <p className={styles.account_position}>{profile.company}</p>
                  )}
                </div>

                <img
                  src="/DropDownIconBlack.png"
                  alt="dropdown"
                  className={`${styles.dropdown_icon_icon} ${
                    showMenu ? styles.rotate_180 : ""
                  }`}
                ></img>
              </div>
            )}
            {showMenu && (
              <div className={styles.menu}>
                {profile.admin && (
                  <>
                    <div
                      className={styles.menu_item}
                      onClick={() => handleLink(firebaseLink)}
                    >
                      <img
                        className={styles.account_menu_icon}
                        src="./database.png"
                        alt="icon"
                      ></img>
                      <p className={styles.menu_item_text}>Firebase</p>
                    </div>
                    <div
                      className={styles.menu_item}
                      onClick={() => handleLink(googleCloudPlatformLink)}
                    >
                      <img
                        className={styles.account_menu_icon}
                        src="./server.png"
                        alt="icon"
                      ></img>
                      <p className={styles.menu_item_text}>Google Cloud</p>
                    </div>

                    <div
                      className={styles.menu_item}
                      onClick={() => handleLink(loggingLink)}
                    >
                      <img
                        className={styles.account_menu_icon}
                        src="./logging.png"
                        alt="icon"
                      ></img>
                      <p className={styles.menu_item_text}>Logging</p>
                    </div>
                  </>
                )}
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="./SettingsIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Settings</p>
                </div>
                <div className={styles.menu_item} onClick={handleLogout}>
                  <img
                    className={styles.account_menu_icon}
                    src="./logout.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logout</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
}
