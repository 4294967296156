import React from "react";

const CancelIcon = ({
  width = 26,
  height = 26,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M6 18L18 6M6 6l12 12" stroke={color} />
  </svg>
);

export default CancelIcon;
