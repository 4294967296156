import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import { debounce } from "lodash";

import { PhyllloContext } from "../../../context/PhylloContext";
import { AuthContext } from "../../../context/AuthContext";
import { phyllo_filter_options } from "./phyllo_filter_options";
import { platform_filter_options } from "./FilterOptions";
import { filter_options } from "./FilterOptions";
import { mapFilters } from "../../../helpers/mapFilters";
import UpgradePlan from "../../PopUpComponents/UpgradePlan/UpgradePlan";


import { toast } from "react-toastify";

import {
  TopicInputComponent,
  MiniInputComponent,
  RangeComponent,
  InputComponent,
  DropdownComponent,
  BoolComponent,
  SliderComponent,
  SearchInputComponent,
} from "./FilterDroptownTypes";

import FiltersSidebar from "./FiltersSidebar";

export default function SearchFilterComponent({
  filterOptions,
  setFilterOptions,
  ...props
}) {
  const { profile, planType, currentFilters } = useContext(AuthContext);

  const { handlePhylloSearch, saveFilterOptions, testFilterMap } =
    useContext(PhyllloContext);

  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);

  const togglePlatformDropdown = () => {
    setIsPlatformDropdownOpen(!isPlatformDropdownOpen);
  };

  const FILTER_OPTIONS = props.FILTER_OPTIONS;

  useEffect(() => {
    const debouncedSave = debounce(async () => {
      try {
        await saveFilterOptions(profile.uid, filterOptions);
        console.log("Filter options saved");
      } catch (error) {
        console.error("Error saving filter options", error);
      }
    }, 500);

    debouncedSave();

    return () => debouncedSave.cancel();
  }, [filterOptions]);

  function handleCreatorSearch() {
    try {
      // testFilterMap(filterOptions);
      handlePhylloSearch(mapFilters(filterOptions));
    } catch (error) {
      toast.error("Error Searching Database!");
    }
  }

  const updateFilterValues = (platform) => {
    const updatedFilterOptions = { ...filterOptions };
    console.log(updatedFilterOptions);
    for (const sectionKey in updatedFilterOptions) {
      if (Object.hasOwnProperty.call(updatedFilterOptions, sectionKey)) {
        const section = updatedFilterOptions[sectionKey];

        for (const filterKey in section) {
          if (
            Object.hasOwnProperty.call(section, filterKey) &&
            section[filterKey].title === "Platform"
          ) {
            section[filterKey].value = platform.name;
            section[filterKey].id = platform.id;
            section[filterKey].logo = platform.logo_url;
          }
          if (filterKey === "subscribers" || filterKey === "followers") {
            if (section[filterKey] && section[filterKey].value !== undefined) {
              delete section[filterKey].value;
            }
          }
        }
      }
    }
    setFilterOptions(updatedFilterOptions);
  };

  const defaultItems = [];
  Object.keys(filterOptions).forEach((sectionKey) => {
    const section = filterOptions[sectionKey];
    Object.keys(section).forEach((itemKey) => {
      const item = section[itemKey];
      if (item.default === "true") {
        defaultItems.push(item);
      }
    });
  });

  const topicFilterItem = Object.entries(filter_options)
    .flatMap(([sectionKey, section]) =>
      Object.entries(section).map(([itemKey, item]) => item)
    )
    .find((item) => item.title === "Topics");

  const emailPreferanceItem = Object.entries(filter_options)
    .flatMap(([sectionKey, section]) =>
      Object.entries(section).map(([itemKey, item]) => item)
    )
    .find((item) => item.title === "Email Preference");

  const [activeDefaultItem, setActiveDefaultItem] = useState(null);

  const dropdownRef = useRef(null);
  const lastClickedElementRef = useRef(null);

  const handleItemClick = (index, event) => {
    event.stopPropagation();
    lastClickedElementRef.current = event.target;
    setActiveDefaultItem(activeDefaultItem === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (lastClickedElementRef.current === event.target) {
        return;
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDefaultItem(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removeAllFilters = () => {
    const updatedFilterOptions = { ...filterOptions };

    for (const sectionKey in updatedFilterOptions) {
      if (Object.hasOwnProperty.call(updatedFilterOptions, sectionKey)) {
        const section = updatedFilterOptions[sectionKey];

        for (const filterKey in section) {
          if (Object.hasOwnProperty.call(section, filterKey)) {
            if (filterKey !== "platform") {
              delete section[filterKey].value;
            }
          }
        }
      }
    }
    setFilterOptions(FILTER_OPTIONS);
  };

  const removeFilter = (sectionKey, filterKey) => {
    const updatedFilterOptions = { ...filterOptions };
    if (
      updatedFilterOptions[sectionKey] &&
      updatedFilterOptions[sectionKey][filterKey]
    ) {
      updatedFilterOptions[sectionKey][filterKey].value = null;
    }
    setFilterOptions(updatedFilterOptions);
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + "K";
    } else {
      return num;
    }
  }

  const formatNumbericalValues = (value) => {
    if (typeof value === "number") {
      return formatNumber(value);
    }
    return value;
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  function valueObjectCheck(input) {
    return typeof input === "object" && input !== null
      ? input.display_name ?? input
      : input;
  }

  function formatFilterText(filter) {
    let filterText = filter.title + ": ";

    if (filter.sliderValue) {
      filterText += "At least " + filter.sliderValue + "% ";
    }

    if (filter.type === "range") {
      if (filter.value[0] && filter.value[1]) {
        filterText +=
          formatNumbericalValues(filter.value[0]) +
          " - " +
          formatNumbericalValues(filter.value[1]);
      } else if (filter.value[0]) {
        filterText +=
          (filter.sliderValue ? "above " : "Above ") +
          formatNumbericalValues(filter.value[0]);
      } else if (filter.value[1]) {
        filterText +=
          (filter.sliderValue ? "below " : "Below ") +
          formatNumbericalValues(filter.value[1]);
      }
    } else {
      if (Array.isArray(filter.value)) {
        filterText += filter.value
          .map((value) => valueObjectCheck(value))
          .join(", ");
      } else {
        filterText += valueObjectCheck(filter.value);
      }
    }

    return filterText;
  }

  const platformRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (platformRef.current && !platformRef.current.contains(event.target)) {
        // Click occurred outside the modal, close the modal
        setIsPlatformDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPlatformDropdownOpen]);

  return (
    <div className={styles.creator_search_component}>
      <UpgradePlan isOpen={props.isPopupOpen} onClose={props.closePopup} />
      <div
        className={`${styles.overlay} ${
          isSidebarVisible ? styles.visible : ""
        }`}
      ></div>
      <FiltersSidebar
        isVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        handleCreatorSearch={handleCreatorSearch}
        removeAllFilters={removeAllFilters}
      />
      {props.type !== "CampaignCreation" && (
        <p className={styles.page_title}>{props.title}</p>
      )}

      <div className={styles.search_layout}>
        <div
          ref={platformRef}
          className={
            isPlatformDropdownOpen
              ? `${styles.platform_filter} ${styles.open}`
              : styles.platform_filter
          }
          onClick={togglePlatformDropdown}
        >
          <img
            src={filterOptions.PLATFORM.platform.logo}
            alt="platform"
            key={filterOptions.PLATFORM.platform.id}
            className={styles.social_filter_icon}
          ></img>
          <img
            src="/images/general_icons/DownArrowIcon.png"
            alt="down"
            className={styles.down_icon}
          ></img>

          <div className={styles.dropdown_menu}>
            {filterOptions.PLATFORM.platform.platform_options.map(
              (platform) => {
                // Convert platform name to lowercase to match allowed_platforms keys
                const platformKey = platform.name.toLowerCase();

                // Check if the platform is allowed
                const isPlatformAllowed =
                  profile?.allowed_platforms[platformKey];

                return (
                  <div
                    className={
                      filterOptions.PLATFORM.platform.value === platform.name
                        ? `${styles.platform_item} ${styles.platform_item_selected}`
                        : styles.platform_item
                    }
                    onClick={() => {
                      if (!isPlatformAllowed) {
                        toast.warning(
                          `${platform.name} is not allowed on your current plan.`
                        );
                        return;
                      }

                      updateFilterValues(platform);
                    }}
                  >
                    <img
                      src={platform.logo_url}
                      alt={platform.name}
                      className={styles.social_filter_icon}
                    />
                    <p className={styles.platform_text}>{platform.name}</p>

                    {/* Conditionally render the lock icon if the platform is not allowed */}
                    {!isPlatformAllowed && (
                      <img
                        className={styles.platform_locked_icon}
                        src="/PasswordIcon.png"
                        alt="locked"
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>

        {/* <input
          className={styles.searchbar}
          placeholder="Enter a topic e.x. Music"
        ></input> */}
        <TopicInputComponent
          filter={topicFilterItem}
          emailPreferanceItem={emailPreferanceItem}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
        />
        {/* <MiniInputComponent
          filter={topicFilterItem}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          placeholder="Add bio phrase"
        />
        <MiniInputComponent
          filter={topicFilterItem}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          placeholder="Add keyword"
        /> */}
        <div className={styles.search_button} onClick={handleCreatorSearch}>
          Search
        </div>
        <div className={styles.filter_button} onClick={toggleSidebar}>
          Filters
          <div className={styles.filter_count}>25</div>
        </div>
      </div>

      <div className={styles.filter_options}>
        {props.displayDefaults && (
          <div className={styles.item_container}>
            {defaultItems.map((item, index) => {
              if (
                (filterOptions.PLATFORM.platform.value === "Instagram" ||
                  filterOptions.PLATFORM.platform.value === "TikTok") &&
                item.title === "Subscribers"
              ) {
                return null;
              }

              // Hide "Followers" for YouTube
              if (
                filterOptions.PLATFORM.platform.value === "YouTube" &&
                item.title === "Followers"
              ) {
                return null;
              }
              return (
                <div className={styles.default_filter_dropdown_wrapper}>
                  {item.value ? (
                    <div
                      className={styles.default_filter_item}
                      key={index}
                      onClick={(e) => handleItemClick(index, e)}
                    >
                      <p className={styles.selected_filter_title}>
                        {item.title}
                      </p>
                      <div className={styles.filter_selected_icon_box}>
                        <img
                          src="/images/general_icons/CheckmarkIcon.png"
                          alt="down"
                          className={styles.filter_selected_icon}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.default_filter_item}
                      key={index}
                      onClick={(e) => handleItemClick(index, e)}
                    >
                      <p className={styles.filter_title}>{item.title}</p>
                      <img
                        src="/images/general_icons/DownArrowIcon.png"
                        alt="down"
                        className={`${styles.filter_down_icon} ${
                          activeDefaultItem === index ? styles.rotate180 : ""
                        }`}
                      />
                    </div>
                  )}
                  {activeDefaultItem === index && (
                    <div
                      className={styles.default_filter_dropdown}
                      ref={dropdownRef}
                    >
                      {item.type === "range" && (
                        <RangeComponent
                          filter={item}
                          setFilterOptions={setFilterOptions}
                          filterOptions={filterOptions}
                        />
                      )}
                      {item.type === "input" && <InputComponent />}
                      {item.type === "search-input" && (
                        <SearchInputComponent
                          filter={item}
                          setFilterOptions={setFilterOptions}
                          filterOptions={filterOptions}
                        />
                      )}
                      {item.type === "dropdown" && (
                        <DropdownComponent
                          filter={item}
                          setFilterOptions={setFilterOptions}
                          filterOptions={filterOptions}
                        />
                      )}
                      {item.type === "bool" && <BoolComponent />}
                      {item.type === "slider" && <SliderComponent />}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div className={styles.current_filter_options_box}>
          <div className={styles.current_filter_options}>
            {Object.keys(filterOptions).map((sectionKey) => {
              const section = filterOptions[sectionKey];
              return Object.keys(section).map((filterKey) => {
                const filter = section[filterKey];
                if (filter.value && filter.title !== "Email Preference") {
                  return (
                    <div className={styles.current_filter_item} key={filterKey}>
                      <p className={styles.current_filter_item_text}>
                        {formatFilterText(filter)}
                      </p>
                      {filter.title !== "Platform" && (
                        <img
                          src="/cancel-icon.png"
                          alt="remove"
                          className={styles.remove_filter_item}
                          onClick={() => removeFilter(sectionKey, filterKey)}
                        />
                      )}
                    </div>
                  );
                }
                return null;
              });
            })}
          </div>
          <p className={styles.remove_filter_button} onClick={removeAllFilters}>
            Remove All Filters
          </p>
        </div>
      </div>
    </div>
  );
}
