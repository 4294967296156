const campaigns_data = [
  {
    id: "1",
    status: "active",
    title: "Consumer Sales Chicago Campaign",
    description:
      "Targeting consumer sales in Chicago with tailored marketing to boost engagement and drive sales growth.",
    contacts_in_campaign: 201,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
  {
    id: "2",
    status: "active",
    title: "Affiliate Recruitment Male Campaign",
    description:
      "Recruiting male affiliates to expand our network, leveraging targeted messaging and incentives.",
    contacts_in_campaign: 301,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
  {
    id: "2",
    status: "active",
    title: "Affiliate Recruitment Female Campaign",
    description:
      "Focused on recruiting female affiliates to diversify our network and amplify brand reach.",
    contacts_in_campaign: 351,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
  {
    id: "2",
    status: "active",
    title: "New York Sales Campaign",
    description:
      "Targeting sales in New York City by tailoring marketing efforts to resonate with the local consumer base.",
    contacts_in_campaign: 701,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
];

export default campaigns_data;
