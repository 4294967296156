import React from "react";

const VerifiedIcon = ({
  width = 18,
  height = 18,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    id="fi_15050690"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <linearGradient
      id="linear-gradient"
      gradientUnits="userSpaceOnUse"
      x1="24"
      x2="24"
      y1="41.994"
      y2="6.007"
    >
      <stop offset="0" stop-color="#0064e1"></stop>
      <stop offset=".9944" stop-color="#26b7ff"></stop>
    </linearGradient>
    <path
      d="m41.92 29.06c-.15.52-.48.94-.95 1.21l-3.22 1.8v3.68c0 1.1-.9 2-2 2h-3.69l-1.79 3.22c-.27.47-.69.8-1.21.95-.51.14-1.05.08-1.52-.18l-3.54-1.98-3.54 1.98c-.31.17-.64.25-.98.25-.1801 0-.36-.02-.54-.07-.52-.15-.94-.48-1.21-.95l-1.79-3.22h-3.69c-1.1 0-2-.9-2-2v-3.68l-3.22-1.8c-.47-.27-.8-.69-.95-1.21-.14-.51-.08-1.05.18-1.52l1.98-3.54-1.98-3.54c-.54-.97-.19-2.19.77-2.73l3.22-1.8v-3.68c0-1.1.9-2 2-2h3.69l1.79-3.22c.27-.47.69-.8 1.21-.95.51-.14 1.05-.08 1.52.1801l3.54 1.98 3.54-1.98c.47-.26 1.0099-.32 1.52-.1801.52.15.94.48 1.21.95l1.79 3.22h3.69c1.1 0 2 .9 2 2v3.68l3.22 1.8c.96.54 1.31 1.76.77 2.73l-1.98 3.54 1.98 3.54c.26.47.32 1.01.1801 1.52z"
      fill="url(#linear-gradient)"
    ></path>
    <path
      d="m22 30c-.2559 0-.5117-.0977-.707-.293l-5-5c-.3906-.3906-.3906-1.0234 0-1.4141s1.0234-.3906 1.4141 0l4.293 4.293 9.293-9.293c.3906-.3906 1.0234-.3906 1.4141 0s.3906 1.0234 0 1.4141l-10 10c-.1953.1953-.4512.293-.707.293z"
      fill="#fff"
    ></path>
  </svg>
);

export default VerifiedIcon;
