import React, { useState, useContext } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import styles from "../auth.module.css";
import CircularLoader from "../../PortalComponents/UniversalComponents/CircularLoader/CircularLoader";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../context/AuthContext";

const CheckoutForm = ({ onNext, formData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { createAccount } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not yet loaded.");
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      console.error(result.error.message);
      toast.error("Error Processing Payment");
    } else {
      if (result.paymentIntent.status === "succeeded") {
        console.log(result);
        console.log("Payment Succeeded!");
        try {
          await createAccount(formData);
          toast.success("Welcome to BrandLink!");
          navigate(`/social-search`);
          setIsLoading(false);
        } catch (error) {
          console.error("Payment Processing Failed", error);
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {isLoading ? (
        <div className={styles.pay_button}>
          <CircularLoader />
        </div>
      ) : (
        <button
          type="submit"
          disabled={!stripe || isLoading}
          className={styles.pay_button}
        >
          Complete Purchase
        </button>
      )}
    </form>
  );
};

export default CheckoutForm;
