import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "../settings.module.css";

export default function SecuritySettings({ profile }) {
  const [changingEmail, setChangingEmail] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);

  return (
    <div className={styles.main_layout}>
      <p className={styles.top_section_title}>Security</p>
      <div className={styles.security_options}>
        <div className={styles.security_option_top}>
          <div className={styles.security_option_left}>
            <p className={styles.security_option_title}>Email Address</p>
            <p className={styles.security_option_subtitle}>
              The parent email address associated with your account.
            </p>
            <p className={styles.email_address}>{profile.email}</p>
          </div>
          <div className={styles.security_option_right}>
            <div className={styles.edit_item_button}>Edit</div>
          </div>
        </div>
        <div className={styles.security_option}>
          <div className={styles.security_option_left}>
            <p className={styles.security_option_title}>Password</p>
            <p className={styles.security_option_subtitle}>
              Set a unique password to protect your account.
            </p>
          </div>
          <div className={styles.security_option_right}>
            <div className={styles.change_password_button}>Change Password</div>
          </div>
        </div>
        <div className={styles.security_option}>
          <div className={styles.security_option_left}>
            <p className={styles.security_option_title}>Delete Account</p>
            <p className={styles.security_option_subtitle}>
              Your account will be permanently deleted from Brandlink.
            </p>
          </div>
          <div className={styles.security_option_right}>
            <div className={styles.delete_button}>Delete Account</div>
          </div>
        </div>
      </div>
    </div>
  );
}
