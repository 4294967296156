import React from "react";

const WarningTriangle = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_4201973"
  >
    <g>
      <path
        d="m57.362 26.54-37.262 64.535a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5l-37.258-64.535a7.665 7.665 0 0 0 -13.276 0z"
        fill="#ee404c"
      ></path>
      <g fill="#fff7ed">
        <rect
          height="29.377"
          rx="4.333"
          width="9.638"
          x="59.181"
          y="46.444"
        ></rect>
        <circle cx="64" cy="87.428" r="4.819"></circle>
      </g>
    </g>
  </svg>
);

export default WarningTriangle;
