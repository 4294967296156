import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreatorSearchComponent from "../../components/PortalComponents/CreatorSearchComponents/CreatorSearchComponent";

const CreatorSearchPage = () => {
  return (
    <>
      <Sidebar
        childComponent={
          <CreatorSearchComponent
            displayDefaults={true}
            title="Social Search"
          />
        }
        activeText="Social Search"
      />
    </>
  );
};

export default CreatorSearchPage;
