import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./profile_sections.module.css";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import BulletChart from "./GeneralComponents/BulletChart";
import AccountList from "./GeneralComponents/AccountList";

export default function SimilarCreatorsSection(props) {
  const profile = props.profile.profile;

  return (
    <div className={styles.section_layout}>
      <div>
        <AccountList
          data={profile.lookalikes}
          title="Account's similar to this Creator"
        />
      </div>
      <div className={styles.section_divider}>
        <div className={styles.section_divider_line}></div>
      </div>
    </div>
  );
}
