import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router";
import React from "react";
import styles from "./general_components.module.css";

export default function AccountList(props) {
  const data = props.data;

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num;
    }
  }

  return (
    <div className={styles.account_list_layout}>
      <p className={styles.section_title}>{props.title}</p>
      <div className={styles.account_info_list}>
        {data.slice(0, 12).map((account, index) => (
          <div key={index} className={styles.account_info}>
            <img
              className={styles.account_pfp}
              src={account.image_url}
              alt="profile"
            ></img>
            <div key={index} className={styles.account_text}>
              <p className={styles.account_username}>
                {account.platform_username}
              </p>
              <p className={styles.account_followers}>
                {formatNumber(account.follower_count)} Followers
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
