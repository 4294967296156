import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
// import InboxComponent from "../../components/PortalComponents/InboxComponents/InboxComponent";
import EmailInboxComponent from "../../components/PortalComponents/EmailInboxComponents/EmailInboxComponents";

const InboxPage = () => {
  return (
    <>
      <Sidebar childComponent={<EmailInboxComponent />} activeText="Your Inboxes" />
    </>
  );
};

export default InboxPage;
