import React, { useState } from "react";
import styles from "./stages.module.css";
import SearchbarHeader from "../../UniversalComponents/SearchbarHeader/SearchbarHeader";
import SearchFilterPopup from "../../../PopUpComponents/SearchFilterPopup/SearchFilterPopup";
import EmptyIcon from "../../../../assets/SVG/EmptyIcon";
import CancelIcon from "../../../../assets/SVG/CancelIcon";

export default function AccountTargeting({ newCampaign, handleInputChange }) {
  const [addingContacts, setAddingContacts] = useState(false);

  function openPopup() {
    setAddingContacts(true);
  }

  function closePopup() {
    setAddingContacts(false);
  }

  const addContactToCampaign = (creators) => {
    console.log(creators);
    let creatorsArray = Array.isArray(creators) ? creators : [creators];

    const uniqueCreators = creatorsArray.filter((creator) => {
      return !newCampaign.campaign_contacts.some(
        (contact) => contact.platform_username === creator.platform_username
      );
    });

    const updatedContacts = [
      ...newCampaign.campaign_contacts,
      ...uniqueCreators,
    ];

    handleInputChange("campaign_contacts", updatedContacts);
  };

  return (
    <div className={styles.campaign_stage}>
      <SearchFilterPopup
        isOpen={addingContacts}
        onClose={closePopup}
        addContactToCampaign={addContactToCampaign}
      />
      <div className={styles.top_titles}>
        <p className={styles.account_targeting}>Add Campaign Targets</p>
        <p className={styles.account_contacts}>
          {newCampaign.campaign_contacts.length} Contacts Added
        </p>
      </div>

      <SearchbarHeader create={true} actionFunction={openPopup} />
      <div className={styles.contacts_header}>
        <div className={styles.basic_info}>
          <p className={styles.header_cell_titles}>Contact</p>
        </div>
        <div className={styles.cell_width}>
          <p className={styles.header_cell_titles}>Email</p>
        </div>
        {/* <div className={styles.cell_width}>
          <p className={styles.header_cell_titles}>Phone</p>
        </div> */}
      </div>
      {newCampaign.campaign_contacts.length === 0 ? (
        <div className={styles.empty_layout}>
          <EmptyIcon />
          <p className={styles.empty_text}>Add Campaign Contacts</p>
        </div>
      ) : (
        <div className={styles.campaign_contacts}>
          {newCampaign.campaign_contacts.map((contact, index) => (
            <div className={styles.contacts_tile}>
              <div className={styles.tile_info}>
                <div className={styles.basic_info}>
                  <img
                    className={styles.creator_profile_picture}
                    src={contact.image_url}
                    alt="profile"
                  ></img>
                  <div className={styles.creator_display_names}>
                    <p className={styles.creator_name}>{contact.full_name}</p>
                    <p className={styles.creator_username}>
                      @{contact.platform_username}
                    </p>
                  </div>
                </div>
                <div className={styles.cell_width}>
                  <p className={styles.header_cell_values}>
                    {contact.platform_username}@gmail.com
                  </p>
                </div>
                {/* <div className={styles.cell_width}>
                  <p className={styles.header_cell_titles}>Phone</p>
                </div> */}
              </div>
              <div className={styles.remove_contact}>
                <CancelIcon width={18} height={18} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
