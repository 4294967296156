import React from "react";

const Reply = ({
  width = 12.5,
  height = 12.5,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.5,
  ...props
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512.001 512.001"
  >
    <g>
      <g>
        <path
          d="M324.104,156.152H174.471l91.949-91.949l-28.268-28.268L97.946,176.141l140.206,140.206l28.268-28.268l-91.949-91.949
			h149.633c81.562,0,147.918,66.356,147.918,147.918c0,38.36-19.398,70.958-35.671,91.548l-12.393,15.682l31.366,24.788
			l12.393-15.682c20.202-25.563,44.284-66.497,44.284-116.336C512,240.441,427.71,156.152,324.104,156.152z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default Reply;
