import React, { useContext, useState } from "react";
import styles from "./create_campaign.module.css";

import { CampaignContext } from "../../../context/CampaignContext";

import CampaignInformation from "./CreateCampaignStages/CampaignInformation";
import AccountTargeting from "./CreateCampaignStages/AccountTargeting";
import MessagingStrategy from "./CreateCampaignStages/MessagingStrategy";
import CampaignConfig from "./CreateCampaignStages/CampaignConfig";
import FinalizeCampaign from "./CreateCampaignStages/FinalizeCampaign";

import CampaignInfoIcon from "../../../assets/SVG/CampaignIcons/CampaignInfoIcon";
import TargetingIcon from "../../../assets/SVG/CampaignIcons/TargetingIcon";
import MessagingIcon from "../../../assets/SVG/CampaignIcons/MessagingIcon";
import ConfigurationIcon from "../../../assets/SVG/CampaignIcons/ConfigurationIcon";
import FinalizeIcon from "../../../assets/SVG/CampaignIcons/FinalizeIcon";

import CircularLoader from "../UniversalComponents/CircularLoader/CircularLoader";

import dayjs from "dayjs";
import { EmailContext } from "../../../context/EmailContext";

import { toast } from "react-toastify";

export default function CreateCampaignComponents({ updating, campaign }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [validLateStage, setValidLastStage] = useState(false);

  console.log(campaign);

  const [isLoading, setIsLoading] = useState(false);

  const { createCampaign } = useContext(CampaignContext);

  const defaultStartTime = dayjs().hour(8).minute(0).second(0);
  const defaultEndTime = dayjs().hour(17).minute(0).second(0);

  const initialCampaignState = {
    campaign_name: null,
    campaign_description: null,
    campaign_contacts: [],
    campaign_message_sequence: [
      {
        first: true,
        type: "email",
        status: "pending",
        from: null,
        subject: null,
        body: null,
        delay: null,
      },
    ],
    campaign_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    campaign_runtime: [defaultStartTime, defaultEndTime],
    start_on: null,
    status: "active",
  };

  const [newCampaign, setNewCampaign] = useState(
    updating ? campaign : initialCampaignState
  );

  const updateCampaignData = (newData) => {
    setNewCampaign({ ...newCampaign, ...newData });
  };

  const handleInputChange = (key, value) => {
    updateCampaignData({ [key]: value });
  };

  const isMessageSequenceValidated =
    newCampaign.campaign_message_sequence.every(
      (message) => message.from && message.subject && message.body
    );

  let steps = [
    {
      step: 1,
      title: "Campaign Information",
      element: (
        <CampaignInformation
          newCampaign={newCampaign}
          handleInputChange={handleInputChange}
        />
      ),
      icon: <CampaignInfoIcon />,
      isValidated:
        newCampaign.campaign_name && newCampaign.campaign_description,
    },
    {
      step: 2,
      title: "Account Targeting",
      element: (
        <AccountTargeting
          newCampaign={newCampaign}
          handleInputChange={handleInputChange}
        />
      ),
      icon: <TargetingIcon />,
      isValidated: newCampaign.campaign_contacts.length !== 0,
    },
    {
      step: 3,
      title: "Messaging Strategy",
      element: (
        <MessagingStrategy
          newCampaign={newCampaign}
          handleInputChange={handleInputChange}
        />
      ),
      icon: <MessagingIcon />,
      isValidated: isMessageSequenceValidated,
    },
    {
      step: 4,
      title: "Configuration",
      element: (
        <CampaignConfig
          newCampaign={newCampaign}
          handleInputChange={handleInputChange}
          setValidLastStage={setValidLastStage}
        />
      ),
      icon: <ConfigurationIcon />,
      isValidated: updating || validLateStage,
    },
  ];

  if (!updating) {
    steps.push({
      step: 5,
      title: "Review & Launch",
      element: (
        <FinalizeCampaign
          newCampaign={newCampaign}
          handleInputChange={handleInputChange}
        />
      ),
      icon: <FinalizeIcon />,
      isValidated:
        newCampaign.campaign_name &&
        newCampaign.campaign_description &&
        newCampaign.campaign_contacts.length !== 0 &&
        isMessageSequenceValidated &&
        validLateStage,
    });
  }

  const handleStepClick = (step) => {
    if (steps[step - 1].isValidated) {
      setCurrentStep(step);
    }
  };

  const nextStage = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStage = () => {
    setCurrentStep(currentStep - 1);
  };

  // function areAllPreviousStepsValidated() {
  //   return (
  //     newCampaign.campaign_name &&
  //     newCampaign.campaign_description &&
  //     newCampaign.campaign_contacts.length > 0 &&
  //     newCampaign.campaign_message_sequence.every(
  //       (message) => message.from && message.subject && message.body
  //     )
  //   );
  // }

  const launchCampaign = async () => {
    setIsLoading(true);
    try {
      await createCampaign(newCampaign);
      toast.success("Campaign Created");
    } catch (error) {
      console.error("Failed to create campaign:", error);
      toast.error("Campaign Failed");
    }
    setIsLoading(false);
  };

  return (
    <div
      className={updating ? styles.main_layout_no_padding : styles.main_layout}
    >
      {!updating && <p className={styles.page_title}>Create Campaign</p>}

      {!updating && (
        <div className={styles.page_header}>
          {steps.map((item, index) => (
            <div
              key={index}
              className={`${styles.line} ${
                currentStep >= item.step ? styles.line_active : ""
              }`}
            ></div>
          ))}
        </div>
      )}

      <div className={styles.page_layout}>
        <div className={styles.step_tracker}>
          {!updating && (
            <p className={styles.stage_completion}>
              <span className={styles.stage_completion_blue}>
                {currentStep}
              </span>
              /{steps.length}
            </p>
          )}
          {steps.map((item, index) => (
            <div
              key={index}
              className={`${styles.step_box} ${
                item.step === currentStep ? styles.step_box_current : ""
              }`}
              onClick={() => handleStepClick(item.step)}
            >
              <div style={{ opacity: item.isValidated ? 1 : 0.4 }}>
                {item.icon}
              </div>

              <p
                className={styles.step_title}
                style={{ opacity: item.isValidated ? 0.75 : 0.4 }}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
        <></>
        <div className={styles.content}>
          {steps[currentStep - 1].element}

          {currentStep !== steps.length ? (
            <div className={styles.bottom_controls}>
              {currentStep !== 1 ? (
                <div className={styles.back_button} onClick={previousStage}>
                  Back
                </div>
              ) : (
                <div className={styles.back_button_disabled}>Back</div>
              )}

              {steps[currentStep - 1].isValidated ? (
                <div className={styles.next_button} onClick={nextStage}>
                  Continue
                </div>
              ) : (
                <div className={styles.next_button_disabled}>Continue</div>
              )}
            </div>
          ) : (
            <div className={styles.bottom_controls}>
              <div
                className={styles.launch_button}
                onClick={!isLoading ? launchCampaign : null}
              >
                {isLoading ? (
                  <CircularLoader size="30%" color="rgba(0, 88, 220, 1)" />
                ) : (
                  "Launch Campaign"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
